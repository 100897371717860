<template>
  <div v-if="page_loading">
    <loading :page_loading="page_loading" />
  </div>
  <div v-else>
    <loading :loading="loading" />
    <v-card class="v-card-bottom-30">
      <v-card-title>
        <a @click="toBack('promotion')">โปรโมชั่น</a>
        <v-icon>mdi-chevron-right</v-icon>
        {{ texttitle }}
      </v-card-title>
    </v-card>

    <v-card elevation="1" class="v-card-bottom-30">
      <v-card-title>
        <div class="py-1">
          <v-icon size="20" color="primary">fa-sliders-h</v-icon
          ><span class="my-3 ml-3"
            >{{ texttitle }}
            <span v-if="nowdateCheck" class="fn-14" style="color: red">
              ไม่สามารถแก้ไขได้เนื่องจากโปรโมชั่นได้เริ่มขึ้นแล้ว!!
            </span></span
          >
        </div>
      </v-card-title>
      <v-divider />
      <v-card-text>
        <v-form ref="promotion" lazy-validation>
          <!-- ชื่อโปรโมชั่น -->
          <v-row>
            <v-col cols="12" md="3" lg="2" class="pb-3">
              <h3 class="fn-14">ชื่อโปรโมชั่น :</h3>
            </v-col>
            <v-col cols="12" md="5"   lg="4" class="pt-1 pb-2">
              <v-text-field
                :value="promotion.name"
                @change="(value) => (promotion.name = Trim_value(value))"
                :rules="validateRules.promotionNameRules"
                ref="promotionname"
                outlined
                dense
                hide-details
                :disabled="nowdateCheck"
                autocomplete="off"
              ></v-text-field>
              <span
                v-if="validateRules.promotionNameValidateText != null"
                style="color: red"
                >{{ validateRules.promotionNameValidateText }}</span
              >
            </v-col>
          </v-row>
          <!-- ช่องทาง -->
          <v-row>
            <v-col cols="12" md="3" lg="2">
              <h3 class="fn-14">ช่องทาง :</h3>
            </v-col>
            <v-col cols="12" md="9" lg="10">
              <div  v-for="i in marketpalce.allData" :key="i.id" class="mt-2">
              
                <v-checkbox
                  v-model="i.selectSeller"
                  color="info"
                  hide-details
                  :disabled="nowdateCheck"
                  :rules="validateRules.channelRules"
                  ref="channelRef"
                  @click="handleSelectCheckboxMarketplace(i, marketpalce.allData.indexOf(i))">
                  <template v-slot:label >
                  <img :src="i.photoLink" style="width: 30px; border-radius:50px !important;" />
                   
                  <span class="ml-2"> {{ i.name }}</span> 
                  </template>
                </v-checkbox>
              </div>
            </v-col>
            <!-- <span v-if="validateRules.channelRulesValidateText != null" class="my-1" style="color: red;">{{ validateRules.channelRulesValidateText  }}</span> -->
          </v-row>
          <!-- เลือกวันเดือนปี -->
          <v-row>
            <v-col cols="12" md="3" lg="2">
              <h3 class="fn-14">วันที่เริ่มต้น :</h3>
            </v-col>
            <v-col cols="12" md="3" lg="2">
              <v-menu
                ref="startDateMenu"
                v-model="startDateMenu"
                :close-on-content-click="true" 
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    :value="formattedStartDate"
                    @change="value => startDatePromotion = value"
                    autocomplete="off"
                    label="เริ่มต้น"
                    outlined
                    prepend-inner-icon="mdi-calendar"
                    readonly
                    dense
                    hide-details
                    v-on="on"
                    clearable
                    @click:clear="startDatePromotion = null"
                  >
                    <template v-slot:label>
                      <span>{{ use_language.start }}</span>
                    </template>
                  </v-text-field>
                </template>
                <v-date-picker
                  v-model="startDatePromotion"
                  @input="startDateMenu = false; updateFormattedStartDate()"
                  no-title
                  class="my-0"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="6" md="3" lg="2">
              <v-menu
                ref="time_pickerStart"
                v-model="time_pickerStart"
                :close-on-content-click="false"
                :nudge-right="40" 
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    :value="timeStart"
                    @change="(value) => (timeStart = value)"
                    autocomplete="off"
                    prepend-inner-icon="mdi-clock"
                    outlined
                    readonly
                    style="width: 250px"
                    v-on="on"
                    hide-details
                    dense
                  ></v-text-field>
                </template>
                <v-time-picker
                  v-if="time_pickerStart"
                  v-model="timeStart"
                  format="24hr"
                  full-width 
                ></v-time-picker>
              </v-menu>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="3" lg="2" class="pt-1 pb-2">
              <h3 class="fn-14">วันที่สิ้นสุด:</h3>
            </v-col>
            <v-col cols="12" md="3" lg="2" class="pt-1 pb-2">
                <v-menu
                  ref="endDateMenu"
                  v-model="endDateMenu"
                  :close-on-content-click="true" 
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      :value="formattedEndDate"
                       
                      autocomplete="off"
                      label="สิ้นสุด"
                      outlined
                      dense 
                      prepend-inner-icon="mdi-calendar"
                      readonly
                      hide-details
                      v-on="on"
                          clearable
                        @click:clear="endDatePromotion = null"
                    >
                      <template v-slot:label>
                        <span>{{ use_language.end }}</span>
                      </template>
                    </v-text-field>
                  </template>
                  <v-date-picker
                    v-model="endDatePromotion"
                    @change="updateFormattedEndDate()"
                    no-title
                    class="my-0"
                  ></v-date-picker>
                </v-menu>


              <!-- ---------- -->
            </v-col>
            <v-col cols="6" md="3" lg="2" class="pt-1 pb-2">
              <!-- เวลา -->
              <!-- <v-combobox
              v-model="promotion.selectedHourEnd"
              :items="promotion.hours"
              outlined
              style="min-width: 200px; max-width: 700px;"
              hide-details
              dense
            ></v-combobox> -->
              <v-menu
                ref="time_pickerEnd"
                v-model="time_pickerEnd"
                :close-on-content-click="false"
                :nudge-right="40" 
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    :value="timeEnd"
                    @change="(value) => (timeEnd = value)"
                    autocomplete="off"
                    prepend-inner-icon="mdi-clock"
                    outlined
                    readonly
                    style="width: 250px"
                    v-on="on"
                    hide-details
                    dense
                  ></v-text-field>
                </template>
                <v-time-picker
                  v-if="time_pickerEnd"
                  v-model="timeEnd"
                  format="24hr"
                  full-width 
                ></v-time-picker>
              </v-menu>
            </v-col>
          </v-row>
          <!-- ประเภทโปรโมชั่น -->
          <v-row>
            <v-col cols="12" md="3" lg="2" class="pt-1 pb-2">
              <h3 class="fn-14">ประเภทโปรโมชั่น :</h3>
            </v-col>
            <v-col cols="12" md="3" lg="2" class="pt-1 pb-2">
              <v-radio-group
                v-model="promotion.promotionType"
                row
                required
                class="mb-2 is-cod-selector"
                dense
                hide-details
                :disabled="nowdateCheck"
              >
                <v-radio label="ยอดเงิน" :value="1">
                  <template v-slot:label> <span>ยอดเงิน</span> </template>
                </v-radio>
                <v-radio label="จำนวนสินค้า" :value="2">
                  <template v-slot:label> <span>จำนวนสินค้า</span> </template>
                </v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
          <!-- ยอดเงินและจำนวน -->
          <v-row v-if="promotion.promotionType === 1">
            <v-col cols="12" md="3" lg="2" class="pt-1 pb-2">
              <h3 class="fn-14">ยอดเงิน :</h3>
            </v-col>
            <v-col cols="12" md="3" lg="2" class="pt-1 pb-2">
              <v-text-field
                :value="promotion.amount"
                @change="(value) => (promotion.amount = Trim_value(value))"
                :rules="validateRules.amountRules"
                ref="promotionamout"
                outlined
                :disabled="nowdateCheck"
                danse
                hide-details
                autocomplete="off"
              ></v-text-field>
              <span v-if="validateRules.amountValidateText != null" style="color: red">{{
                validateRules.amountValidateText
              }}</span>
            </v-col>
            <v-col cols="12" md="3" class="pt-3"> บาท </v-col>
          </v-row>

          <v-row v-if="promotion.promotionType === 2">
            <v-col cols="12" md="3" lg="2" class="pt-1 pb-3">
              <h3 class="fn-14">จำนวนสินค้า :</h3>
            </v-col>
            <v-col cols="12" md="3" lg="2" class="pt-1 pb-2">
              <v-text-field
                :value="promotion.quantity"
                @change="(value) => (promotion.quantity = Trim_value(value))"
                :rules="validateRules.quantityRules"
                ref="promotionquantity"
                outlined
                :disabled="nowdateCheck"
                danse
                hide-details
                autocomplete="off"
              ></v-text-field>
              <span
                v-if="validateRules.quantityValidateText != null"
                style="color: red"
                >{{ validateRules.quantityValidateText }}</span
              >
            </v-col>
            <v-col cols="12" md="3" class="pt-3"> ชิ้น </v-col>
          </v-row>
          <!-- สามารถซ้อนทับกับโปรอื่นได้หรือไม่ -->
          <v-row>
            <v-col cols="12" md="3" lg="2">
              <h3 class="fn-14">สามารถซ้อนทับกับโปรอื่นได้หรือไม่ :</h3>
            </v-col>
            <v-col cols="12" md="3" lg="2">
              <v-radio-group
                v-model="promotion.duplicate"
                row
                required
                :disabled="nowdateCheck"
                class="mb-2 is-cod-selector"
                dense
                hide-details
              >
                <v-radio label="ได้" :value="true">
                  <template v-slot:label> <span>ได้</span> </template>
                </v-radio>
                <v-radio label="ไม่ได้" :value="false">
                  <template v-slot:label> <span>ไม่ได้</span> </template>
                </v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
          <!-- ลำดับความสำคัญ -->
          <v-row v-if="!promotion.duplicate">
            <v-col cols="12" md="3" lg="2">
              <h3 class="fn-14">ลำดับความสำคัญ :</h3>
            </v-col>
            <v-col cols="12" md="3" lg="2">
              <v-text-field
                :value="promotion.priority"
                @change="(value) => (promotion.priority = Trim_value(value))"
                type="number"
                outlined
                ref="promotionpriority"
                :disabled="nowdateCheck"
                dense
                hide-details
                autocomplete="off"
                placeholder="กรุณาระบุตัวเลขลำดับความสำคัญ"
              ></v-text-field>
              <span
                v-if="validateRules.priorityValidateText != null"
                style="color: red"
                >{{ validateRules.priorityValidateText }}</span
              >
            </v-col>
          </v-row>
          <!-- สินค้าที่ร่วมรายการ  -->
          <v-row>
            <v-col cols="12" md="3" lg="2">
              <h3 class="fn-14">สินค้าที่ร่วมรายการ :</h3>
            </v-col>
            <v-col cols="12" md="3" lg="3">
              <v-radio-group
                v-model="promotion.joinProgram"
                row
                required
                :disabled="nowdateCheck"
                class="mb-2 is-cod-selector"
                dense
                hide-details
              >
                <v-radio label="ซื้ออะไรก็ได้" :value="true">
                  <template v-slot:label> <span>ซื้ออะไรก็ได้</span> </template>
                </v-radio>
                <v-radio label="จำกัดรายการสินค้า" :value="false">
                  <template v-slot:label> <span>จำกัดรายการสินค้า</span> </template>
                </v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
          <v-row v-if="!promotion.joinProgram">
            <v-col class="pb-0" align="left">
              <v-btn
                color="primary"
                class="mr-1"
                :disabled="nowdateCheck"
                outlined
                @click="handleOpenDialogJoinPromotion(limits, 0, 1)"
              >
                <v-icon center>mdi-plus</v-icon>
                {{ use_language.select_product }}
              </v-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="pb-0">
              <v-data-table
                :headers="[
                  {
                    text: '#',
                    value: 'index',
                    align: 'center',
                    sortable: false,
                    width: 10,
                  },
                  {
                    text: use_language.picture,
                    value: 'photoLink',
                    align: 'center',
                    sortable: false,
                    width: '50px',
                  },
                  {
                    text: 'SKU',
                    value: 'sku',
                    align: 'center',
                    sortable: false,
                    width: '80px',
                  },
                  {
                    text: use_language.name_product,
                    value: 'name',
                    align: 'left',
                    sortable: false,
                    width: '400px',
                  },
                  {
                    text: use_language.quantity,
                    value: 'quantityInput',
                    align: 'center',
                    sortable: false,
                    width: '120px',
                  },
                  {
                    text: use_language.delete,
                    value: 'delete',
                    align: 'center',
                    sortable: false,
                    width: 10,
                  },
                ]"
                :items="showItemSelect"
                :items-per-page="99999"
                hide-default-footer
                class="packhai-border-table packhai-table"
              >
                <template v-slot:no-data>
                  <span v-if="!promotion.joinProgram">{{
                    use_language.please_select_product
                  }}</span>
                  <span v-if="promotion.joinProgram">ทุกสินค้าร่วมรายการ</span>
                </template>
                <template v-slot:item.index="{ item }">
                  {{ productList.indexOf(item) + 1 }}
                </template>
                <template v-slot:item.name="{ item }">
                  <v-row>
                    <v-col cols="12" align="left" class="pa-0">
                      <span class="fn-13" style="font-weight: bold">{{ item.name }}</span>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="12" align="left" class="pa-0">
                      <span class="color-gray-13"
                        >{{ !item.barcode ? "" : use_language.barcode + " : " }}
                        {{ item.barcode }}</span
                      >
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="12" align="left" class="pa-0">
                      <span class="color-gray-13">
                        {{ !item.productCode ? "" : use_language.number_product + " : " }}
                        {{ item.productCode }}
                        {{
                          item.productCode &&
                          (item.prop1Description || item.prop2Description)
                            ? ","
                            : ""
                        }}
                        {{
                          !item.prop1Description && !item.prop2Description
                            ? ""
                            : use_language.type + "  : "
                        }}
                        {{ item.prop1Description }} {{ item.prop2Description }}
                      </span>
                    </v-col>
                  </v-row>
                </template>
                <template v-slot:item.photoLink="{ item }">
                  <a
                    v-if="item.photoLink != null && item.photoLink != ''"
                    icon
                    @click="OpenPhoto(item.photoLink)"
                  >
                    <img :src="item.photoLink" width="50" height="50" class="mt-1" />
                  </a>
                </template>
                <template v-slot:item.quantityInput="{ item }">
                  <v-text-field
                    :value="item.quantityInput"
                    @change="(value) => (item.quantityInput = value)"
                    autocomplete="off"
                    dense
                    hide-details
                    outlined
                    :disabled="nowdateCheck"
                    ref="quantityInput"
                    type="number"
                    @blur="sumQuentityJoinTable(item)"
                  ></v-text-field>
                  <!-- <span v-if="quantityInput_error != null" class="my-1" style="color: red;">{{ quantityInput_error }}</span> -->
                </template>
                <template v-slot:item.delete="{ item }">
                  <v-btn v-if="nowdateCheck" icon disabled
                    ><v-icon>mdi-delete-outline</v-icon></v-btn
                  >
                  <v-btn
                    v-else
                    icon
                    color="red"
                    @click="handleDeleteProductTableClick(item, 1)"
                    ><v-icon>mdi-delete-outline</v-icon></v-btn
                  >
                </template>
              </v-data-table>
              <v-divider></v-divider>
              <v-row justify="end" v-if="this.joinPromotion.totalquantity > 0">
                <v-col lg="2">
                  <v-row class="py-3">
                    <v-col> จำนวนรวม </v-col>
                    <v-col cols="12" lg="5" align="left">
                      <b>{{ formatMoney(this.joinPromotion.totalquantity) }} ชิ้น</b>
                    </v-col>
                    <v-col class="py-2">
                      <span
                        v-if="validateRules.totalquantityValidateText != null"
                        style="color: red"
                        >{{ validateRules.totalquantityValidateText }}</span
                      >
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <!-- สินค้าที่ไม่ร่วมรายการ  -->
          <v-row class="pt-5">
            <v-col cols="12" md="3" lg="2" class="pt-1 pb-2">
              <h3 class="fn-14">สินค้าที่ไม่ร่วมรายการ :</h3>
            </v-col>
            <v-col cols="12" md="3" lg="2" class="pt-1 pb-2">
              <v-radio-group
                v-model="promotion.notjoinProgram"
                row
                required
                :disabled="nowdateCheck"
                class="mb-2 is-cod-selector"
                dense
                hide-details
              >
                <v-radio label="มี" :value="true">
                  <template v-slot:label> <span>มี</span> </template>
                </v-radio>
                <v-radio label="ไม่มี" :value="false">
                  <template v-slot:label> <span>ไม่มี</span> </template>
                </v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
          <v-row v-if="promotion.notjoinProgram">
            <v-col class="pb-0" align="left">
              <v-btn
                color="primary"
                class="mr-1"
                outlined
                :disabled="nowdateCheck"
                @click="handleOpenDialogJoinPromotion(limits, 0, 2)"
              >
                <v-icon center>mdi-plus</v-icon>
                {{ use_language.select_product }}
              </v-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="pb-0">
              <v-data-table
                :headers="[
                  {
                    text: '#',
                    value: 'index',
                    align: 'center',
                    sortable: false,
                    width: 10,
                  },
                  {
                    text: use_language.picture,
                    value: 'photoLink',
                    align: 'center',
                    sortable: false,
                    width: '50px',
                  },
                  {
                    text: 'SKU',
                    value: 'sku',
                    align: 'center',
                    sortable: false,
                    width: '80px',
                  },
                  {
                    text: use_language.name_product,
                    value: 'name',
                    align: 'left',
                    sortable: false,
                    width: '400px',
                  },
                  {
                    text: use_language.delete,
                    value: 'delete',
                    align: 'center',
                    sortable: false,
                    width: 10,
                  },
                ]"
                :items="notjoinproductList"
                :items-per-page="99999"
                hide-default-footer
                class="packhai-border-table packhai-table"
              >
                <template v-slot:no-data>
                  <span v-if="promotion.notjoinProgram">{{
                    use_language.please_select_product
                  }}</span>
                  <span v-if="!promotion.notjoinProgram">ทุกสินค้าร่วมรายการ</span>
                </template>
                <template v-slot:item.index="{ item }">
                  {{ notjoinproductList.indexOf(item) + 1 }}
                </template>
                <template v-slot:item.name="{ item }">
                  <v-row>
                    <v-col cols="12" align="left" class="pa-0">
                      <span class="fn-13" style="font-weight: bold">{{ item.name }}</span>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" align="left" class="pa-0">
                      <span class="color-gray-13"
                        >{{ !item.barcode ? "" : use_language.barcode + " : " }}
                        {{ item.barcode }}</span
                      >
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" align="left" class="pa-0">
                      <span class="color-gray-13">
                        {{ !item.productCode ? "" : use_language.number_product + " : " }}
                        {{ item.productCode }}
                        {{
                          item.productCode &&
                          (item.prop1Description || item.prop2Description)
                            ? ","
                            : ""
                        }}
                        {{
                          !item.prop1Description && !item.prop2Description
                            ? ""
                            : use_language.type + "  : "
                        }}
                        {{ item.prop1Description }} {{ item.prop2Description }}
                      </span>
                    </v-col>
                  </v-row>
                </template>
                <template v-slot:item.photoLink="{ item }">
                  <a
                    v-if="item.photoLink != null && item.photoLink != ''"
                    icon
                    @click="OpenPhoto(item.photoLink)"
                  >
                    <img :src="item.photoLink" width="50" height="50" class="mt-1" />
                  </a>
                </template>
                <template v-slot:item.delete="{ item }">
                  <v-btn v-if="nowdateCheck" icon disabled
                    ><v-icon>mdi-delete-outline</v-icon></v-btn
                  >
                  <v-btn
                    v-else
                    icon
                    color="red"
                    @click="handleDeleteProductTableClick(item, 2)"
                    ><v-icon>mdi-delete-outline</v-icon></v-btn
                  >
                </template>
              </v-data-table>
            </v-col>
          </v-row>
          <!-- ประเภทการแถม -->
          <v-row class="pt-5">
            <v-col cols="12" md="3" lg="2" class="pt-1 pb-2">
              <h3 class="fn-14">ประเภทการแถม :</h3>
            </v-col>
            <v-col cols="12" md="3" lg="4" class="pt-1 pb-2">
              <v-radio-group
                v-model="promotion.productFreeType"
                row
                required
                :disabled="nowdateCheck"
                class="mb-2 is-cod-selector"
                dense
                hide-details
              >
                <v-radio label="จนกว่าสินค้าจะหมด" :value="1">
                  <template v-slot:label><span>จนกว่าสินค้าจะหมด</span> </template>
                </v-radio>
                <v-radio label="จำกัดที่" :value="2">
                  <template v-slot:label>
                    <span>จำกัดที่ </span>
                    <v-row v-if="promotion.productFreeType === 2">
                      <v-col>
                        <v-text-field
                          :value="promotion.productFreeOrder"
                          @change="
                            (value) => (promotion.productFreeOrder = Trim_value(value))
                          "
                          outlined
                          :disabled="nowdateCheck"
                          :rules="validateRules.freeMinOrderRules"
                          ref="reffreeMinOrder"
                          dense
                          hide-details
                          autocomplete="off"
                        ></v-text-field>
                        <span
                          v-if="validateRules.freeMinOrderValidateText != null"
                          style="color: red"
                          >{{ validateRules.freeMinOrderValidateText }}</span
                        >
                      </v-col>
                      <v-col class="pt-5">
                        <span>ออเดอร์ </span>
                      </v-col>
                    </v-row>
                  </template>
                </v-radio>
                <v-radio label="จำกัดจำนวนสินค้า" :value="3">
                  <template v-slot:label> <span>จำกัดจำนวนสินค้า</span> </template>
                </v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="pb-0" align="left">
              <v-btn
                color="primary"
                class="mr-1"
                outlined
                @click="handleOpenDialogJoinPromotion(limits, 0, 3)"
                :disabled="nowdateCheck"
              >
                <v-icon center>mdi-plus</v-icon>
                {{ use_language.select_product }}
              </v-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="pb-0">
              <v-data-table
                :headers="freeProductHeader"
                :items="freeProductList"
                :items-per-page="99999"
                hide-default-footer
                class="packhai-border-table packhai-table"
              >
                <template v-slot:no-data><span>กรุณาเลือกสินค้า</span> </template>
                <template v-slot:item.index="{ item }">
                  {{ freeProductList.indexOf(item) + 1 }}
                </template>
                <template v-slot:item.name="{ item }">
                  <v-row>
                    <v-col cols="12" align="left" class="pa-0">
                      <span class="fn-13" style="font-weight: bold">{{ item.name }}</span>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="12" align="left" class="pa-0">
                      <span class="color-gray-13"
                        >{{ !item.barcode ? "" : use_language.barcode + " : " }}
                        {{ item.barcode }}</span
                      >
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="12" align="left" class="pa-0">
                      <span class="color-gray-13">
                        {{ !item.productCode ? "" : use_language.number_product + " : " }}
                        {{ item.productCode }}
                        {{
                          item.productCode &&
                          (item.prop1Description || item.prop2Description)
                            ? ","
                            : ""
                        }}
                        {{
                          !item.prop1Description && !item.prop2Description
                            ? ""
                            : use_language.type + "  : "
                        }}
                        {{ item.prop1Description }} {{ item.prop2Description }}
                      </span>
                    </v-col>
                  </v-row>
                </template>
                <template v-slot:item.photoLink="{ item }">
                  <a
                    v-if="item.photoLink != null && item.photoLink != ''"
                    icon
                    @click="OpenPhoto(item.photoLink)"
                  >
                    <img :src="item.photoLink" width="50" height="50" class="mt-1" />
                  </a>
                </template>
                <template v-slot:item.quantityInput="{ item }">
                  <v-text-field
                    :value="item.quantityInput"
                    @change="(value) => (item.quantityInput = value)"
                    autocomplete="off"
                    dense
                    hide-details
                    outlined
                    :rules="validateRules.freeMinquantityRules"
                    ref="quantityInputfree"
                    type="number"
                    @blur="sumQuentityFreeTable(item)"
                  ></v-text-field>
                  <span
                    v-if="
                      item.quantityInput <= 0 && validateRules.freeMinquantityText != null
                    "
                    class="my-1"
                    style="color: red"
                    >{{ validateRules.freeMinquantityText }}</span
                  >
                </template>
                <template v-slot:item.limitquantityInput="{ item }">
                  <v-text-field
                    :value="item.limitquantityInput"
                    @change="(value) => (item.limitquantityInput = value)"
                    autocomplete="off"
                    dense
                    hide-details
                    outlined
                    type="number"
                    @blur="sumQuentityFreeTable(item)"
                  ></v-text-field>
                </template>
                <template v-slot:item.giveAwayQuantity="{ item }">
                  <b>{{ formatMoney(item.giveAwayQuantity) }} ชิ้น</b>
                </template>
                <template v-slot:item.delete="{ item }">
                  <v-btn v-if="nowdateCheck" icon disabled
                    ><v-icon>mdi-delete-outline</v-icon></v-btn
                  >
                  <v-btn
                    v-else
                    icon
                    color="red"
                    @click="handleDeleteProductTableClick(item, 3)"
                    ><v-icon>mdi-delete-outline</v-icon></v-btn
                  >
                </template>
              </v-data-table>
              <v-divider></v-divider>
              <v-row justify="end" v-if="joinPromotion.freeTotalquantity > 0">
               
                   
                    <v-col cols="12" lg="5" align="right">
                        จำนวนรวม  
                      <b>{{ formatMoney(joinPromotion.freeTotalquantity) }} ชิ้น</b>
                    </v-col>
                    <!-- {{ validateRules.freelimitValidateText }} -->
                
              </v-row>
            </v-col>
          </v-row>
        </v-form>
        <div v-show="nowdateCheck"></div>
      </v-card-text>
      <v-card-actions class="py-5">
        <v-row>
          <v-col cols="12" align="right">
            <v-btn
              v-if="nowdateCheck"
              :elevation="1"
              color="primary"
              class="px-5"
              disabled
            >
              <v-icon size="20" class="mr-1">mdi-content-save</v-icon>
              <span class="fn-14"
                ><b> {{ use_language.record }} </b></span
              >
            </v-btn>
            <v-btn
              v-else
              :elevation="1"
              color="primary"
              class="px-5"
              @click="handleAddPromotionClick()"
            >
              <v-icon size="20" class="mr-1">mdi-content-save</v-icon>
              <span class="fn-14"
                ><b> {{ use_language.record }} </b></span
              >
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
    <v-card v-if="PromotionID != null">
      <v-card-title>
        <v-row>
          <v-col cols="12" md="6">
            <h3 class="fn-14">
              รายการออเดอร์ที่ได้รับของแถม จำนวนทั้งหมด {{ orderMaster.total }} ออเดอร์
            </h3>
          </v-col>
          <v-col cols="12" md="6" align="right">
            <vue-excel-xlsx
              id="GetExcelAll"
              v-show="false"
              :data="orderMaster.dataExcel"
              :columns="orderMaster.headerExcel"
              :filename="ExcelFile"
              :sheet-name="'sheet1'"
            >
            </vue-excel-xlsx>
            <v-btn
              :elevation="1"
              outlined
              color="success"
              class="pa-2 ml-2"
              :disabled="orderMaster.data.length < 1"
              @click="exportexcel()"
            >
              <v-icon size="14" class="pr-1">fa-solid fa-download</v-icon>
              <span class="fn-12"> Export Excel </span>
              <span v-if="orderMaster.loadingExcel != 0"
                >({{ orderMaster.loadingExcel }}%)</span
              >
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col class="pb-0" cols="12">
            <v-data-table
              :headers="orderMaster.headers"
              :items="orderMaster.data"
              :items-per-page="99999"
              hide-default-footer
              class="packhai-border-table packhai-table"
            >
              <template v-slot:no-data><span>ยังไม่มีออเดอร์ที่ได้รับ</span> </template>
              <template v-slot:item.No="{ item }">
                {{
                  orderMaster.total - orderMaster.offsets - orderMaster.data.indexOf(item)
                }}
              </template>
              <template v-slot:item.orderMasterID="{ item }">
                <a class="text-info" @click="detail_page(item.orderMasterID)">{{
                  FormatOrder(item.orderMasterID)
                }}</a>
              </template>
              <template v-slot:item.referenceSourceID="{ item }">
                <a v-if="item.channelLogo != null && item.channelLogo != ''" icon>
                  <img :src="item.channelLogo" width="30" height="30" class="mt-1" />
                </a>
              </template>
              <template v-slot:item.totalPrice="{ item }">
                <span>{{ formatMoneyIsDecimal(item.totalPrice) }}</span>
              </template>
               <template v-slot:item.totalSellerDiscount="{ item }">
                <span v-if="item.totalSellerDiscount==0"></span>
                <span v-else>{{ formatMoneyIsDecimal(item.totalSellerDiscount) }}</span>
              </template>
              <template v-slot:item.totalPlatformDiscount="{ item }">
                <span v-if="item.totalPlatformDiscount==0"></span>
                <span v-else>{{ formatMoneyIsDecimal(item.totalPlatformDiscount) }}</span>
              </template>
              <template v-slot:item.deliveryCost="{ item }">
                <span v-if="item.deliveryCost==0"></span>
                <span v-else>{{ formatMoneyIsDecimal(item.deliveryCost) }}</span>
              </template>
               <template v-slot:item.sellerDeliveryDiscount="{ item }">
                <span v-if="item.sellerDeliveryDiscount==0"></span>
                <span v-else>{{ formatMoneyIsDecimal((item.sellerDeliveryDiscount)) }}</span>
              </template>
              <template v-slot:item.orderTotalAmount="{ item }">
                <span>{{ formatMoneyIsDecimal(item.orderTotalAmount) }}</span>
              </template>
            </v-data-table>
            <div
              v-if="orderMaster.pageLength > 1 && orderMaster.data.length > 0"
              class="text-center pa-1"
              @click="changePageOrderMaster()"
            >
              <v-pagination
                v-model="orderMaster.page"
                :length="orderMaster.pageLength"
                :total-visible="orderMaster.pageTotalVisible"
                color="error"
              ></v-pagination>
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <v-dialog v-model="joinPromotion.dialog" persistent scrollable max-width="80%">
      <v-card elevation="1" class="ma-0">
        <v-card-title class="height-57 pa-0">
          <div class="v-list-item">
            <div class="pa-2">
              <span
                ><h5>{{ use_language.search }} :</h5></span
              >
            </div>
            <div class="v-list-item__content pa-2">
              <v-text-field
                ref="refsearchProductPopup"
                :value="joinPromotion.searchProductPopup"
                autocomplete="off"
                class="pl-2"
                dense
                hide-details
                @change="
                  (value) => (joinPromotion.searchProductPopup = Trim_value(value))
                "
                @keyup.enter="PopupsearchStock(limits, 0)"
              ></v-text-field>
            </div>
            <div class="pa-2">
              <v-btn
                color="primary"
                class="mr-1 px-2"
                @click="PopupsearchStock(limits, 0)"
              >
                <v-icon center>mdi-magnify</v-icon>
                {{ use_language.search }}
              </v-btn>
            </div>
            <div>
              <v-btn icon @click="joinPromotion.dialog = false"
                ><v-icon color="danger">mdi-close</v-icon></v-btn
              >
            </div>
          </div>
        </v-card-title>
        <v-divider />

        <!-- PC -->
        <v-card-text class="overflow-x-auto pt-1 pb-1 hidden-xs-only">
          <v-data-table
            :headers="joinPromotion.headers"
            :items="joinPromotion.data"
            item-key="id"
            :items-per-page="999999"
            hide-default-footer
            class="elevation-0 packhai-checkbox-select"
          >
            <template v-slot:item.selectProduct="{ item }">
              <v-checkbox
                v-model="item.selectProduct"
                color="red"
                hide-details
                @click="
                  handleSelectCheckboxProduct(item, joinPromotion.data.indexOf(item))
                "
              ></v-checkbox>
            </template>
            <template v-slot:item.No="{ item }">
              {{ joinPromotion.data.indexOf(item) + 1 + offsets }}
            </template>
            <template v-slot:item.photoLink="{ item }">
              <v-btn
                v-if="item.photoLink != null && item.photoLink != ''"
                icon
                @click="OpenPhoto(item.photoLink)"
              >
                <img :src="item.photoLink" height="30" />
              </v-btn>
            </template>
            <template v-slot:item.name="{ item }">
              {{ item.name }}
            </template>
            <template v-slot:item.prop1="{ item }">
              <span class="fn-13">
                {{
                  (item.prop1_Description != null && item.prop1_Description != ""
                    ? item.prop1_Description
                    : "") +
                  (item.prop2_Description != null && item.prop2_Description != ""
                    ? " , " + item.prop2_Description
                    : "")
                }}
              </span>
            </template>
            <template v-slot:item.salePrice="{ item }">
              <span>
                {{
                  formatMoney(
                    item.salePrice == null || item.salePrice == "" || item.salePrice == 0
                      ? "0.00"
                      : item.salePrice
                  )
                }}
              </span>
            </template>
          </v-data-table>
        </v-card-text>
        <!-- PC -->

        <div
          v-if="pageLength > 1 && joinPromotion.data.length > 0"
          class="text-center pa-1"
          @click="changePage()"
        >
          <v-pagination
            v-model="page"
            :length="pageLength"
            :total-visible="pageTotalVisible"
            color="error"
          ></v-pagination>
        </div>

        <v-divider />
        <div class="height-57 pa-2" align="right">
          <v-btn color="primary" class="mr-2" @click="handleAddProductClick()">
            {{ use_language.ad_product }}
          </v-btn>
        </div>
      </v-card>
    </v-dialog>

    <!-- แสดงภาพ -->
    <v-dialog v-model="ShowPhoto" max-width="500">
      <v-card class="ma-0">
        <v-card-text>
          <v-row align="center">
            <v-col cols="12" class="text-center">
              <img class="hidden-md-and-up" :src="ShowPhotoLink" width="300" />
              <img class="hidden-sm-and-down" :src="ShowPhotoLink" width="450" />
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-btn text color="#1976d2" @click="Opentap(ShowPhotoLink)">{{
            use_language.newtab
          }}</v-btn>
          <v-spacer></v-spacer>
          <v-btn text color="#1976d2" @click="ShowPhoto = false">{{
            use_language.close
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Loading from "@/website/components/Loading";
import axios from "axios";
import Swal from "sweetalert2";
import { shopService_dotnet } from "@/website/global";
import {
  formatDatetime,
  formatMoney,
  formatMoneyIsDecimal,
  isNumberWNoDot,
  isNumberWithDot,
  isNumberWitMark,
  Trim_value,
  set_format_date_time,
  format_vat_type,
  format_price,
  get_languages,
  GenIE,
  GenPA,
  FormatOrder,
} from "@/website/global_function"; 
export default {
  components: {
    Loading,
  },
  data: () => ({
    header_token:{'Content-Type': 'application/json', 'Authorization': 'Bearer '+localStorage.getItem('authorization_token')},
    page_loading: true,
    loading: false,
    shopID: null,
    productList: [],
    notjoinproductList: [],
    freeProductList: [],
    productImportType: null,
    isedit: false,
    PromotionID: null,
    nowdateCheck: false,
    startDateMenu: false,
    startDatePromotion: '',
    formattedStartDate: '',
    endDateMenu: false,
    endDatePromotion: '',
    formattedEndDate: '',
    promotion: {
      name: null,
      channel: [],
      promotionType: 1,
      productFreeType: 1,
      productFreeOrder: null,
      amount: null,
      quantity: null,
      duplicate: true,
      priority: null,
      joinProgram: true,
      notjoinProgram: false,
      selectedHourStart: null,
      selectedHourEnd: null,
      hours: [],
      deleteItemIDlist: [],
    },
    joinPromotion: {
      headers: [
        {
          text: "เลือก",
          align: "center",
          sortable: false,
          value: "selectProduct",
          width: "50px",
        },
        { text: "No", align: "center", sortable: false, value: "No", width: "60px" },
        {
          text: "รูป",
          align: "center",
          sortable: false,
          value: "photoLink",
          width: "60px",
        },
        { text: "ชื่อ", align: "left", sortable: false, value: "name" },
        { text: "ลักษณะ", align: "center", sortable: false, value: "prop1" },
        {
          text: "รหัสสินค้า",
          align: "center",
          sortable: false,
          value: "productCode",
          width: "90px",
        },
        { text: "SKU", align: "center", sortable: false, value: "sku" },
        { text: "Barcode", align: "center", sortable: false, value: "barcode" },
        {
          text: "ราคา",
          align: "right",
          sortable: false,
          value: "salePrice",
          width: "90px",
        },
      ],
      data: [],
      productSelect: [],
      dialog: false,
      searchProductPopup: null,
      searchNamePopup: null,
      totalquantity: 0,
      freeTotalquantity: 0,
      limitTotalquantity: 0,
    },
    notJoinPromotion: {
      productnotjoin: false,
    },
    marketpalce: {
      shopee: [],
      lazada: [],
      tiktok: [],
      woo: [],
      linemyshop: [],
      shopify: [],
      allData: [],
      selectMarketpalce: [],
    },
    ExcelFile:'ออเดอร์ที่ได้รับของแถม',
    orderMaster: {
      
      headers: [
        { text: "No", align: "center", sortable: false, value: "No", width: "60px" },
        {
          text: "หมายเลขออเดอร์",
          align: "left",
          sortable: false,
          value: "orderMasterID",
          width: "100px",
        },
        {
          text: "เลขอ้างอิง",
          align: "left",
          sortable: false,
          value: "referenceNumber",
          width: "140px",
        },
        {
          text: "วันที่ชำระเงิน",
          align: "left",
          sortable: false,
          value: "PaymenDate",
          width: "140px",
        },
        {
          text: "ช่องทาง",
          align: "center",
          sortable: false,
          value: "referenceSourceID",
          width: "60px",
        },
        {
          text: "ร้านค้า",
          align: "left",
          sortable: false,
          value: "marketplaceSellerName",
          width: "140px",
        },
        {
          text: "ราคาสินค้า",
          align: "right",
          sortable: false,
          value: "totalPrice",
          width: "80px",
        },
        {
          text: "ส่วนลด Seller",
          align: "right",
          sortable: false,
          value: "totalSellerDiscount",
          width: "80px",
        },
        {
          text: "ส่วนลด Platform",
          align: "right",
          sortable: false,
          value: "totalPlatformDiscount",
          width: "80px",
        },
        {
          text: "ค่าขนส่ง",
          align: "right",
          sortable: false,
          value: "deliveryCost",
          width: "80px",
        },
        {
          text: "ส่วนลดค่าขนส่ง",
          align: "right",
          sortable: false,
          value: "sellerDeliveryDiscount",
          width: "80px",
        },
        {
          text: "ราคาทั้งหมดหลังหักส่วนลด",
          align: "right",
          sortable: false,
          value: "orderTotalAmount",
          width: "120px",
        },
      ],
      data: [],
      orderID: null,
      //excel
      headerExcel: [
        { label: "No", field: "No" },
        { label: "หมายเลขออเดอร์", field: "orderMasterID" },
        { label: "เลขอ้างอิง", field: "referenceNumber" },
        { label: "วันที่ชำระเงิน", field: "Date" },
        { label: "ช่องทาง", field: "referenceSourceID" },
        { label: "ร้านค้า", field: "marketplaceSellerName" },
        { label: "ราคาสินค้า", field: "totalPrice" },
        { label: "ส่วนลด seller", field: "totalSellerDiscount" },
        { label: "ส่วนลด platform", field: "totalPlatformDiscount" },
        { label: "ค่าขนส่ง", field: "deliveryCost" },
        { label: "ส่วนลดค่าขนส่ง", field: "sellerDeliveryDiscount" },
        { label: "ยอดขายหลังหักส่วนลด", field: "orderTotalAmount" },
      ],
      dataExcel: [],
      loadingExcel: 0,
      // paginations
      page: 1,
      pageTemp: 1,
      pageLength: 1,
      pageTotalVisible: 9,
      limits: 10,
      offsets: 0,
      total: 0,
    },
    validateRules: {
      promotionNameRules: [],
      promotionNameValidateText: null,
      channelRules: [],
      channelRulesValidateText: null,
      priorityRules:[],
      priorityValidateText:null,
      amountRules: [],
      amountValidateText: null,
      quantityRules: [],
      quantityValidateText: null,
      totalquantityValidateText: null,
      freeMinOrderValidateText: null,
      freeMinOrderRules: [],
      freeMinquantityRules: [],
      freeMinquantityText: null,
      freelimitValidateText: null,
    },

    // แสดงภาพ
    ShowPhoto: false,
    ShowPhotoLink: "",

    // paginations
    page: 1,
    pageTemp: 1,
    pageLength: 1,
    pageTotalVisible: 9,
    limits: 10,
    offsets: 0,
    total: 0,
    formatDataTabel: true,

    // time picker
    timeStart:
      Number(new Date().toISOString().substr(11, 2)) +
      7 +
      ":" +
      new Date().toISOString().substr(14, 2),
    time_pickerStart: false,
    timeEnd:
      Number(new Date().toISOString().substr(11, 2)) +
      7 +
      ":" +
      new Date().toISOString().substr(14, 2),
    time_pickerEnd: false,

    // ตามวันที่
     
    disableDate: false,
  }),
  computed: {
    freeProductHeader() {
      let header = [
        { text: "#", value: "index", align: "center", sortable: false, width: 10 },
        {
          text: "รูปภาพ",
          value: "photoLink",
          align: "center",
          sortable: false,
          width: "50px",
        },
        { text: "SKU", value: "sku", align: "center", sortable: false, width: "80px" },
        {
          text: "รายละเอียด",
          value: "name",
          align: "left",
          sortable: false,
          width: "400px",
        },
        {
          text: "จำนวนที่แถมในแต่ละออเดอร์",
          value: "quantityInput",
          align: "center",
          sortable: false,
          width: "120px",
        },
        {
          text: "จำกัดจำนวน",
          value: "limitquantityInput",
          align: "center",
          sortable: false,
          width: "120px",
        },
        {
          text: "แถมไปแล้วทั้งหมด",
          value: "giveAwayQuantity",
          align: "center",
          sortable: false,
          width: "120px",
        },
        { text: "ลบ", value: "delete", align: "center", sortable: false, width: 10 },
      ];
      if (this.promotion.productFreeType === 1 || this.promotion.productFreeType === 2) {
        const index = header.findIndex((header) => header.value === "limitquantityInput");
        if (index > -1) header.splice(index, 1);
      }
      if (!this.nowdateCheck) {
        const index = header.findIndex((header) => header.value === "giveAwayQuantity");
        if (index > -1) header.splice(index, 1);
      }
      return header;
    },
    texttitle() {
      var text;
      this.isedit ? (text = "แก้ไขโปรโมชั่น") : (text = "เพิ่มโปรโมชั่น");
      return text;
    },
    showItemSelect() {
      if (!this.promotion.joinProgram) {
        return this.productList;
      } else {
        return [];
      }
    },
  },
  watch: {
    promotion: {
      handler(item) {
        if (this.promotionID === null) {
          if (item.joinProgram) {
            this.productList = [];
          } else if (!item.notjoinProgram) {
            this.notjoinproductList = [];
          }
        }
      },
      deep: true,
    },
  },
  async created() {
    //เก็บภาษามาไว้ใน use_language
    this.use_language = await this.get_languages(localStorage.getItem("set_language"));
    this.shopID = parseInt(localStorage.getItem("shop_id"));
    await this.getAllMarketpalce();
  
    this.PromotionID = this.$route.query.id
    if (this.PromotionID != null || this.PromotionID != undefined) {
      this.isedit = true;
      
      await this.getPromotionForEdit(this.PromotionID);
      await this.getOrderMasterFreeProductList(this.orderMaster.limits, 0, 1, null);
    } else{
      var d = new Date();
      this.startDatePromotion=d.getFullYear()+"-"+(d.getMonth()+1)+"-"+d.getDate();
      this.endDatePromotion =d.getFullYear()+"-"+(d.getMonth()+1)+"-"+d.getDate();
      console.log( this.startDatePromotion);
      this.updateFormattedStartDate() 
      this.updateFormattedEndDate() 
    }
    this.page_loading = false;
  },

  methods: {
    FormatOrder,
    formatDatetime,
    formatMoney,
    formatMoneyIsDecimal,
    isNumberWNoDot,
    isNumberWithDot,
    isNumberWitMark,
    set_format_date_time,
    format_vat_type,
    format_price,
    get_languages,
    GenIE,
    GenPA,
    Trim_value,
    // allowedDates: (val) => val <= new Date().toISOString().substr(0, 10),
    async handleOpenDialogJoinPromotion(limits, offsets, type) {
      this.joinPromotion.searchProductPopup = null;
      this.joinPromotion.select = [];
      this.joinPromotion.data = [];
      this.joinPromotion.productSelect = [];
      this.productImportType = type; //กำหนดtype ไว้ตอนadd product ใส่ ตาราง จะได้เรียงตารางถูก

      this.page = 1;
      this.pageTemp = 1;
      this.getSelectProduct(
        limits,
        offsets,
        this.joinPromotion.searchProductPopup,
        this.page
      );
    },
    async getOrderMasterFreeProductList(limits, offsets, page, search) {
      let response = await axios.post(
        shopService_dotnet + "Promotion/get-promotion-ordermaster",
        {
          promotionID: this.PromotionID,
          orderId: search,
          skip: offsets,
          take: limits,
        },
        { headers: this.header_token }
      );
      if (response.data.status) {
        this.orderMaster.pageTemp = page;
        this.orderMaster.offsets = offsets;
        this.orderMaster.total = response.data.dataCount;
        this.orderMaster.pageLength = Math.ceil(
          this.orderMaster.total / this.orderMaster.limits
        );
        if (this.orderMaster.pageLength <= 0) {
          this.orderMaster.pageLength = 1;
        }
        const OrderData = response.data.promotionOrderMasters.map((x) => {
          const paymentDate = new Date(x.paymentDate);
          const forma = this.formateDate(paymentDate);
          const baseObject = {
            ...x,
            PaymenDate: forma,
          };
          baseObject.referenceNumber=baseObject.referenceNumber.replace("Tiktok-","")
          baseObject.totalPlatformDiscount= baseObject.totalPlatformDiscount*-1;
          baseObject.totalSellerDiscount= baseObject.totalSellerDiscount*-1;
          baseObject.sellerDeliveryDiscount= (baseObject.sellerDeliveryDiscount+baseObject.platformDeliveryDiscount)*-1 ;
          return baseObject;
        });

        this.orderMaster.data = OrderData;
      }
    },
    async getSelectProduct(limits, offsets, searchstock, page) {
      this.loading = true;
      //filter product ที่เลือกไปแล้วทุกตาราง
      const selectAllProduct = [
        ...this.productList.map((item) => item.id),
        ...this.notjoinproductList.map((item) => item.id),
        ...this.freeProductList.map((item) => item.id),
      ];
      let response = await axios.post(
        shopService_dotnet + "Promotion/get-stock-product-for-promotion",
        {
          shopID: this.shopID,
          keyword: searchstock,
          skipID: selectAllProduct,
          skip: offsets,
          take: limits,
        },
        { headers: this.header_token }
      );

      if (response.status == 200) {
        this.pageLength = Math.ceil(response.data.searchResultCount / this.limits);
        if (this.pageLength <= 0) {
          this.pageLength = 1;
        }
        this.page = page;
        this.pageTemp = page;

        this.joinPromotion.data = response.data.productItem;
        this.joinPromotion.dialog = true;
        this.loading = false;
      } else {
        this.loading = false;
        this.$swal({
          position: "top",
          type: "error",
          text: "ไม่สามารถทำรายการได้",
        });
      }
    },
    async getAllMarketpalce() {
      this.loading = true;
      let response = await axios.get(
        shopService_dotnet +
          "Promotion/get-marketplace-for-promotion?ShopID=" +
          this.shopID,
        { headers: this.header_token }
      );
      if (response.status == 200) {
        var photo;
        var shop_shopee = [];
        var shop_lazada = [];
        var shop_tiktok = [];
        var shop_Woo = [];
        var shop_Line = [];
        var shop_Shopify = [];
        if (response.data.shopee_Shop.length > 0) {
          photo = "https://seeklogo.com/images/S/shopee-logo-DD5CAE562A-seeklogo.com.png";
          shop_shopee = await this.formatDataMarketplace(
            response.data.shopee_Shop,
            photo,
            3
          );
        }
        if (response.data.lazada_Shop.length > 0) {
          photo =
            "https://www.blognone.com/sites/default/files/styles/thumbnail/public/topics-images/laz.png?itok=rN2J8uw-";
          shop_lazada = await this.formatDataMarketplace(
            response.data.lazada_Shop,
            photo,
            4
          );
        }
        if (response.data.tiktok_Shop.length > 0) {
          photo =
            "https://packhaistorage.s3.ap-southeast-1.amazonaws.com/image/TikTok-Logo-1.png";
          shop_tiktok = await this.formatDataMarketplace(
            response.data.tiktok_Shop,
            photo,
            11
          );
        }
        if (response.data.shop_Woo.length > 0) {
          photo = "https://packhaistorage.s3.ap-southeast-1.amazonaws.com/image/woo.png";
          shop_Woo = await this.formatDataMarketplace(response.data.shop_Woo, photo, 144);
        }
        if (response.data.shop_Line.length > 0) {
          photo =
            "https://packhaistorage.s3.ap-southeast-1.amazonaws.com/image/linemyshop.png";
          shop_Line = await this.formatDataMarketplace(response.data.shop_Line, photo, 8);
        }
        if (response.data.shop_Shopify.length > 0) {
          photo =
            "https://packhaistorage.s3.ap-southeast-1.amazonaws.com/image/Screenshot+2024-03-09+004759.png";
          shop_Shopify = await this.formatDataMarketplace(
            response.data.shop_Shopify,
            photo,
            6
          );
        }
        this.marketpalce.allData = [
          shop_shopee,
          shop_lazada,
          shop_tiktok,
          shop_Woo,
          shop_Line,
          shop_Shopify,
        ].reduce((acc, curr) => acc.concat(curr), []);
        this.loading = false;
      } else {
        this.loading = false;
        this.$swal({
          position: "top",
          type: "error",
          text: "ไม่สามารถทำรายการได้",
        });
      }
    },
    async getPromotionForEdit(promotionID) {
      this.loading = true;
      let response = await axios.get(
        shopService_dotnet +
          "Promotion/get-pomotion-for-edit?PromotionID=" +
          promotionID +
          "&ShopID=" +
          this.shopID,
        { headers: this.header_token }
      );
      if (response.data.status) {
        const promotion = response.data.promotion;
        this.promotion.name = promotion.name;
        document.title=promotion.name;
        this.promotion.promotionType = promotion.promotionType;
        this.promotion.quantity = promotion.minQuantity;
        this.promotion.amount = promotion.minSpend;
        this.promotion.priority = promotion.priority;
        this.promotion.duplicate = promotion.isOverlapped;
        var checkstartDate = new Date(promotion.startDate);
        var nowdate = new Date();
        // เปรียบเทียบเวลา
        if (checkstartDate.getTime() > nowdate.getTime()) {
          this.nowdateCheck = false;
        } else {
          this.nowdateCheck = true;
        }
     
        this.startDatePromotion=promotion.startDate.substring(0,10) ;
        this.endDatePromotion =promotion.endDate.substring(0,10) ;
        this.updateFormattedStartDate() 
        this.updateFormattedEndDate() 

 
        this.timeStart = promotion.startDate.substring(11,16);
        this.timeEnd = promotion.endDate.substring(11,16);

        this.promotion.productFreeOrder = promotion.minOrderFreeProduct;
        this.promotion.productFreeType = promotion.freePromotionType;
        //
        const promotionitem = response.data.promotionItems;
        for (var i of promotionitem) {
          if (i.productType === 1) {
            if (i.productMasterItem !== null) {
              this.promotion.joinProgram = false;
              let productItemList = { ...i.productMasterItem };
              productItemList.quantityInput = i.quantity;
              productItemList.promotionitemID = i.id;
              this.productList.push(productItemList);
              this.sumQuentityJoinTable(i.quantity);
            }
          }
          if (i.productType === 2) {
            if (i.productMasterItem !== null) {
              this.promotion.notjoinProgram = true;
              let notjoinitemproductList = { ...i.productMasterItem };
              notjoinitemproductList.promotionitemID = i.id;
              this.notjoinproductList.push(notjoinitemproductList);
            }
          }
          if (i.productType === 3) {
            if (i.productMasterItem !== null) {
              let freeproductItemList = { ...i.productMasterItem };
              freeproductItemList.quantityInput = i.quantity;
              freeproductItemList.promotionitemID = i.id;
              freeproductItemList.limitquantityInput = i.limitQuantity;
              freeproductItemList.giveAwayQuantity = i.giveAwayQuantity;
              this.freeProductList.push(freeproductItemList);
              this.sumQuentityFreeTable(i.quantity);
            }
          }
        }
        //
        const promotionMarketplace = response.data.promotionChannels;

        var data = promotionMarketplace.filter((x) =>
          this.marketpalce.allData.map((i) => i.sellerID).includes(x.platformSellerID)
        );
        const idsSet = new Set(data.map((item) => item.platformSellerID));
        this.marketpalce.allData.forEach((item, index) => {
          if (idsSet.has(item.sellerID)) {
            const findIDmatching = promotionMarketplace.find(
              (pm) => pm.platformSellerID === item.sellerID
            );
            if (findIDmatching) {
              this.$set(this.marketpalce.allData, index, {
                ...item,
                selectSeller: true,
                uniqueID: findIDmatching.id,
              });
            }
          }
        });
        this.marketpalce.allData.forEach((item) => {
          this.handleSelectCheckboxMarketplace(item);
        });
        this.loading = false;
      }
    },
    handleSelectCheckboxProduct(item) {
      if (item["selectProduct"]) {
        this.joinPromotion.productSelect.splice(0, 0, item);
      } else {
        var data = this.joinPromotion.productSelect;
        for (var i in data) {
          if (data[i]["id"] == item["id"]) {
            this.joinPromotion.productSelect.splice(i, 1);
            break;
          }
        }
      }
    },
    handleSelectCheckboxMarketplace(item) {
      if (item.selectSeller) {
        this.marketpalce.selectMarketpalce.splice(0, 0, item);
      } else {
        const removeitem = this.marketpalce.selectMarketpalce.findIndex(
          (selectedItem) => selectedItem === item
        );
        if (removeitem !== -1) {
          this.marketpalce.selectMarketpalce.splice(removeitem, 1);
        }
      }
    },
    async handleAddProductClick() {
      let targetList;
      switch (this.productImportType) {
        case 1:
          targetList = this.productList;
          break;
        case 2:
          targetList = this.notjoinproductList;
          break;
        case 3:
          targetList = this.freeProductList;
          break;
      }
      for (let product of this.joinPromotion.productSelect) {
        targetList.push(product);
      }
      this.joinPromotion.dialog = false;
    },
    async handleDeleteProductTableClick(item, type) {
      let list;
      switch (type) {
        case 1:
          list = this.productList;
          this.joinPromotion.totalquantity -= item.quantityInput;
          break;
        case 2:
          list = this.notjoinproductList;
          break;
        case 3:
          list = this.freeProductList;
          this.joinPromotion.freeTotalquantity -= item.quantityInput;
          this.joinPromotion.limitTotalquantity -= item.limitquantityInput;
          break;
      }
      const index = list.indexOf(item);
      if (index > -1) {
        list.splice(index, 1);
      }

      this.promotion.deleteItemIDlist.push(item.promotionitemID || 0);
    },
    async sumQuentityJoinTable() {
      var totalq = 0;
      var qq = 0;
      // วนลูปจำนวนทั้งหมดตามlength ของ productList
      this.productList.forEach((e, index) => {
        qq = Number.isNaN(Number(this.productList[index].quantityInput))
          ? 0
          : Number(this.productList[index].quantityInput);
        if (qq > 0) {
          totalq += qq;
        }
      });
      this.joinPromotion.totalquantity = totalq;
    },
    async sumQuentityFreeTable() {
      var totalq = 0;
      var qq = 0;
      var limitqq = 0;
      var limittotalq = 0;
      // วนลูปจำนวนทั้งหมดตามlength ของฟรี
      this.freeProductList.forEach((e, index) => {
        qq = Number.isNaN(Number(this.freeProductList[index].quantityInput))
          ? 0
          : Number(this.freeProductList[index].quantityInput);
        limitqq = Number.isNaN(Number(this.freeProductList[index].limitquantityInput))
          ? 0
          : Number(this.freeProductList[index].limitquantityInput);
        if (qq > 0) {
          totalq += qq;
        }
        if (limitqq > 0) [(limittotalq += limitqq)];
      });
      this.joinPromotion.freeTotalquantity = totalq;
      this.joinPromotion.limitTotalquantity = limittotalq;
    },
    async PopupsearchStock(limits, offsets) {
      if (this.joinPromotion.searchProductPopup != null) {
        this.joinPromotion.searchNamePopup = this.joinPromotion.searchProductPopup.trim();
        this.loading = true;
        if (this.joinPromotion.searchNamePopup.length > 0) {
          this.getSelectProduct(limits, offsets, this.joinPromotion.searchNamePopup, 1);
        } else {
          this.getSelectProduct(limits, offsets, null, 1);
        }
      }
    },
    async handleAddPromotionClick() {
      await this.clearRules();
     
      // if (!this.marketpalce.allData.some(x => x.selectSeller === true)) {
      //   this.validateRules.channelRules = [ v => !!v || "กรุณาระบุ" ]
      //   this.validateRules.channelRulesValidateText = 'โปรดเลือกช่องทางขายอย่างน้อย1ช่องทาง'
      //   this.$refs.channelRef.focus();
      //   return;
      // }
      var ispass=true;
      if (this.promotion.productFreeType === 2) {
        if (
          this.promotion.productFreeOrder === null ||
          this.promotion.productFreeOrder === ""
        ) {
          this.validateRules.freeMinOrderValidateText = "กรุณาระบุ";
          this.validateRules.freeMinOrderRules = [(v) => !!v || "กรุณาระบุ"];
          this.$refs.reffreeMinOrder.focus();
          ispass=false;
        }
      }
     
      if (!this.promotion.duplicate) {
        if (this.promotion.priority === null || this.promotion.priority === "") {
          this.validateRules.priorityRules = [(v) => !!v || "กรุณาระบุ"];
          this.validateRules.priorityValidateText = "กรุณาระบุลำดับความสำคัญ";
          this.$refs.promotionpriority.focus();
          ispass=false;
        }
      }
      if (this.promotion.promotionType === 1) {
        if (this.promotion.amount === null || this.promotion.amount === "") {
          this.validateRules.amountRules = [(v) => !!v || "กรุณาระบุ"];
          this.validateRules.amountValidateText = "กรุณาระบุยอดขาย";
          this.$refs.promotionamout.focus();
          ispass=false;
        }
      }
      if (this.promotion.promotionType === 2) {
        if (this.promotion.quantity === null || this.promotion.quantity === "") {
          this.validateRules.quantityRules = [(v) => !!v || "กรุณาระบุ"];
          this.validateRules.quantityValidateText = "กรุณาระบุจำนวน";
          this.$refs.promotionquantity.focus();
          ispass=false;
        }
      }
      if (this.promotion.name === null || this.promotion.name === "") {
        this.validateRules.promotionNameRules = [(v) => !!v || "กรุณาระบุ"];
        this.validateRules.promotionNameValidateText = "กรุณาระบุชื่อโปรโมชั่น";
        this.$refs.promotionname.focus();
        ispass=false;
      }
      if(!ispass){
        return;
      }

      
     
      
      if (!this.promotion.joinProgram && this.promotion.promotionType === 2) {
        if (this.promotion.quantity < this.joinPromotion.totalquantity) { 
          this.$swal({ 
            type: "warning",
            text: "จำนวนสินค้ารวมที่ร่วมรายการต้องน้อยกว่าหรือเท่ากับจำนวนสินค้ารวมทั้งหมด",
          });
          return;
         
        }
      }
      if (this.freeProductList.length > 0) {
        if (
          this.joinPromotion.freeTotalquantity === null ||
          this.joinPromotion.freeTotalquantity === 0
        ) {
          this.validateRules.freeMinquantityRules = [(v) => !!v || "กรุณาระบุ"];
          this.validateRules.freeMinquantityText = "กรุณาใส่จำนวน";
          this.$refs.quantityInputfree.focus();
          return;
        }
      }
      // if( this.promotion.productFreeType === 3)
      // {
      //   if(this.joinPromotion.freeTotalquantity < this.joinPromotion.limitTotalquantity)
      //   {
      //     this.validateRules.freelimitValidateText = 'จำนวนสินค้าจำกัดต้องน้อยกว่าจำนวนของแถมทั้งหมด'
      //     return;
      //   }
      // }
    
      var marketplact_list = this.marketpalce.selectMarketpalce.map((i) => ({
                              ID: this.isedit ? i.uniqueID : 0,
                              channelID: i.channelID,
                              sellerID: i.sellerID.toString(),
                            }));
      if(marketplact_list.length==0){
        this.$swal({ 
          type: "warning",
          text: "กรุณาเลือกช่องทางการขาย",
        });
        return;
      }
      const dataPromo = await this.formatDataPromotion();
      var datenow = new Date();
      var tempStart = new Date(dataPromo.StartDate);
      var tempEnd = new Date(dataPromo.EndDate);
      if((dataPromo.id==0 || dataPromo.id==null) &&datenow>tempStart){
        this.$swal({ 
          type: "warning",
          text: "วันที่เริ่มโปรโมชั่น ไม่สามารถเป็นวันที่ในอดีตได้",
        });
        return;
      }
      if(tempEnd<=tempStart){
        this.$swal({ 
          type: "warning",
          text: "วันที่สิ้นสุดโปรโมชั่น ต้องมากกว่าวันที่เริ่มต้นโปรโมชั่น!",
        });
        return;
      }
      let productlistsets = [];
      if (!this.promotion.joinProgram) {
        productlistsets = await this.formatProductList(this.productList, 1);
      }
      let notjoinproductListsets = await this.formatProductList(
        this.notjoinproductList,
        2
      );
      let freeProductlistsets = await this.formatProductList(this.freeProductList, 3);
      if(freeProductlistsets.length==0){
        this.$swal({ 
          type: "warning",
          text: "กรุณาเลือกสินค้าแถม!",
        });
        return;
      }
      const ProductItem = [
        productlistsets,
        notjoinproductListsets,
        freeProductlistsets,
      ].reduce((acc, curr) => acc.concat(curr), []);
      // if (!this.marketpalce.allData.some(x => x.selectSeller === true)) {
      //   this.$swal({
      //       position: "top",
      //       type: "warning",
      //       text: "กรุณาเลือกช่องทาง",
      //     })
      //   return;
      // }
      if (this.$refs.promotion.validate()) {
        let response = await axios.post(
          shopService_dotnet + "Promotion/add-promotion",
          {
            shopID: this.shopID,
            promotion: dataPromo,
            promotionItems: ProductItem,
            promotionChannels: marketplact_list,
            deletedItemIDList: this.promotion.deleteItemIDlist,
          },
          { headers: this.header_token }
        );
        if (response.data.status) {
          localStorage.removeItem("promotionID");
          this.$router.push("/promotion");
        }
      }
    },
      updateFormattedStartDate() {
          this.formattedStartDate = this.formatDate(this.startDatePromotion);
      },
      updateFormattedEndDate() {
          this.formattedEndDate = this.formatDate(this.endDatePromotion); 
      },
    async exportexcel() {
      this.orderMaster.dataExcel = [];
      this.orderMaster.loadingExcel = 0;
      //เรียกใช้Export
      var limits = 50;
      var offsets = 0;
      this.orderMaster.loadingExcel = 1;
      var total = this.orderMaster.total;
      var round = parseInt(total / limits);
      if (limits * round < total) {
        round = round + 1;
      }
      var no = 0;
      const channelName = {
        3: "Shopee",
        4: "Lazada",
        11: "tiktok",
        144: "Woo",
        8: "LineMyShop",
        6: "Shopify",
      };
      for (var i = 1; i <= round; i++) {
        var data = await this.getOrderMasterFreeProductListExcel(limits, offsets);
        for (var j in data) {
          no += 1;
          this.orderMaster.dataExcel.push({
            No: no,
            orderMasterID: this.GenPA(data[j].orderMasterID),
            Date: this.formatDatetime(data[j].paymentDate),
            referenceSourceID: channelName[data[j].referenceSourceID] || null,
            referenceNumber: data[j].referenceNumber.replace("Tiktok-",""),
            totalPrice :  data[j].totalPrice,
            deliveryCost :   data[j].deliveryCost,
            marketplaceSellerName :   data[j].marketplaceSellerName,
            sellerDeliveryDiscount :   data[j].sellerDeliveryDiscount,
            totalPlatformDiscount :   data[j].totalPlatformDiscount,
            totalSellerDiscount :   data[j].totalSellerDiscount,
            orderTotalAmount:  data[j].orderTotalAmount ,
          });
        }
        this.orderMaster.loadingExcel = (this.orderMaster.dataExcel.length * 100) / total;
        if (round < 100) {
          this.orderMaster.loadingExcel = parseInt(this.orderMaster.loadingExcel);
        } else {
          this.orderMaster.loadingExcel = this.orderMaster.loadingExcel.toFixed(1);
        }
        //เพิ่มtake
        offsets = offsets + limits;
      }
      this.orderMaster.loadingExcel = this.orderMaster.dataExcel.length == 0 ? 0 : 100;
      document.getElementById("GetExcelAll").click();
    },
    async getOrderMasterFreeProductListExcel(limits, offsets) {
      try {
        let response = await axios.post(
          shopService_dotnet + "Promotion/get-promotion-ordermaster",
          {
            promotionID: this.PromotionID,
            skip: offsets,
            take: limits,
          },
          { headers: this.header_token }
        );
        if (response.data.status) {
          return response.data.promotionOrderMasters;
        }
      } catch {
        this.$swal({
          toast: true,
          timer: 3000,
          showConfirmButton: false,
          position: "center",
          type: "error",
          title: "เกิดข้อผิดพลาดไม่สามารถดาวน์โหลดได้",
        });
      }
    },
    async formatDataPromotion() {
      // set data

      let fulldateStart = this.startDatePromotion + " " + this.timeStart + ":00";
      let fulldateEnd = this.endDatePromotion + " " + this.timeEnd + ":00";
      let promotion = {};
      this.isedit == true
        ? (promotion.ID = parseInt(this.PromotionID))
        : (promotion.ID = 0);
      promotion.StartDate = fulldateStart;
      promotion.EndDate = fulldateEnd;
      promotion.Name = this.promotion.name;
      promotion.MinOrderFreeProduct = this.promotion.productFreeOrder;
      promotion.promotionType = this.promotion.promotionType;
      promotion.CreatedBy = parseInt(localStorage.getItem("staff_id"));
      if (this.promotion.promotionType === 1) {
        promotion.MinSpend = this.promotion.amount;
        promotion.MinQuantity = null;
      } else {
        promotion.MinQuantity = this.promotion.quantity;
        promotion.MinSpend = null;
      }
      promotion.IsOverlapped = this.promotion.duplicate;
      if (!this.promotion.duplicate) {
        promotion.priority = this.promotion.priority;
      } else {
        promotion.priority = null;
      }
      promotion.FreePromotionType = this.promotion.productFreeType;
      return promotion;
    },
    async formatProductList(productList, productType) {
      return productList.map((e) => {
        const baseObject = {
          id: this.isedit == true ? e.promotionitemID || 0 : 0,
          ProductMasterItemID: e.id,
          productType: productType,
        };
        if (productType === 1) {
          baseObject.Quantity = Number.isNaN(Number(e.quantityInput))
            ? null
            : Number(e.quantityInput);
        }
        if (productType === 3) {
          baseObject.Quantity = Number.isNaN(Number(e.quantityInput))
            ? null
            : Number(e.quantityInput);
          if (this.promotion.productFreeType === 3) {
            baseObject.LimitQuantity = Number.isNaN(Number(e.limitquantityInput))
              ? null
              : Number(e.limitquantityInput);
          }
        }
        return baseObject;
      });
    },
    async formatDataMarketplace(item, photo, channelID) {
      // ยัดรูปเข้าobjectของMarketplace ที่response ออกมาทุกobject
      return item.map((e) => {
        const baseObject = {
          ...e, //เป็นการใช้ spread operator และเพิ่ม photolink เข้าไปด้วยในการคืนค่าของ map
          photoLink: photo,
          channelID: channelID,
        };
        if (baseObject.channelID === 3) {
          baseObject.sellerID = e.shopeeShopId;
          baseObject.name = e.shopeeShopName;
        } else if (baseObject.channelID === 4) {
          baseObject.sellerID = e.lazadaSellerId;
          baseObject.name = e.lazadaShopName;
        } else if (baseObject.channelID === 11) {
          baseObject.sellerID = e.tiktok_shop_id;
          baseObject.name = e.shop_name;
        } else if (baseObject.channelID === 144) {
          baseObject.sellerID = e.id.toString();
          baseObject.name = e.baseUrl;
        } else if (baseObject.channelID === 8) {
          baseObject.sellerID = e.id.toString();
          baseObject.name = e.shopName;
        } else if (baseObject.channelID === 6) {
          baseObject.sellerID = e.id.toString();
          baseObject.name = e.baseUrl;
        }
        return baseObject;
      });
    },
    formatDate(date) {
        if (!date) return null
        const [year, month, day] = date.split('-')
        return `${day}/${month}/${year}`
    },
    parseDate(date) { 
       console.log("parseDate ",date);
      if (!date) return null;
      const [day, month, year] = date.split("-");
        
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
    async changePage() {
      if (this.pageLength != 1 && this.pageTemp != this.page) {
        this.offsets = this.page * this.limits - this.limits;
        await this.getSelectProduct(
          this.limits,
          this.offsets,
          this.joinPromotion.searchNamePopup,
          this.page
        );
        this.pageTemp = this.page;

        if (this.joinPromotion.productSelect.length > 0) {
          for (var item_1 of this.joinPromotion.productSelect) {
            for (var item_2 of this.joinPromotion.data) {
              if (item_1.id == item_2.id) {
                item_2.selectProduct = true;
                continue;
              }
            }
          }
        }
      }
    },
    async changePageOrderMaster() {
      if (
        this.orderMaster.pageLength != 1 &&
        this.orderMaster.pageTemp != this.orderMaster.page
      ) {
        this.orderMaster.offsets =
          this.orderMaster.page * this.orderMaster.limits - this.orderMaster.limits;
        await this.getOrderMasterFreeProductList(
          this.orderMaster.limits,
          this.orderMaster.offsets,
          this.page,
          null
        );
        this.orderMaster.pageTemp = this.orderMaster.page;
      }
    },
    async setRules() {},
    async clearRules() {
      this.validateRules.promotionNameRules = [];
      this.validateRules.promotionNameValidateText = null;
      this.validateRules.amountRules = [];
      this.validateRules.amountValidateText = null;
      this.validateRules.quantityRules = [];
      this.validateRules.quantityValidateText = null;
      this.validateRules.totalquantityValidateText = null;
      this.validateRules.freelimitValidateText = null;
      this.validateRules.freeMinOrderValidateText = null;
      this.validateRules.freeMinOrderRules = [];
      this.validateRules.freeMinquantityRules = [];
      this.validateRules.freeMinquantityText = null;
      this.validateRules.priorityRules = [];
      this.validateRules.priorityValidateText = null;
    },
    OpenPhoto(Photo) {
      this.ShowPhotoLink = Photo;
      this.ShowPhoto = true;
    },
    Opentap(PhotoLink) {
      window.open(PhotoLink);
    },
    CheckDateTime(index, startDate, endDate) {
      if (startDate != null && endDate != null) {
        var PstartDate = startDate;
        var PendDate = endDate;
        if (new Date(startDate) > new Date(endDate)) {
          PstartDate = endDate;
          PendDate = startDate;
        }
        startDate = PstartDate;
        endDate = PendDate;
      }
      if (index == 0) {
        this.startDate = startDate;
        this.endDate = endDate;
        this.dateStart = this.formatDate(startDate);
        this.dateEnd = this.formatDate(endDate);
      }
    },
    async toBack(path) {
      localStorage.removeItem("promotionID");
      this.$router.push("/" + path);
    },
    detail_page(token) {
      var redirectPath = `${window.location.origin}/order-detail?id=${token}`;
      window.open(redirectPath, "_blank");
    },
    formatLocalDate(date) {
      let year = date.getFullYear();
      let month = date.getMonth() + 1;
      let day = date.getDate();
      let hours = date.getHours();
      let minutes = date.getMinutes();

      month = month < 10 ? "0" + month : month;
      day = day < 10 ? "0" + day : day;
      hours = hours < 10 ? "0" + hours : hours;
      minutes = minutes < 10 ? "0" + minutes : minutes;

      return `${year}-${month}-${day}/${hours}:${minutes}`;
    },
    formateDate(date) {
    console.log(date);
      var year = date.getFullYear();
      var month = date.getMonth() + 1;
      var day = date.getDate();
      var hours = date.getHours().toString().padStart(2, "0");
      var minutes = date.getMinutes().toString().padStart(2, "0");
       var second = date.getSeconds().toString().padStart(2, "0");
      const dateformat = `${day}/${month}/${year} ${hours}:${minutes}:${second}`;
      return dateformat;
    },
  },
};
</script>

<style></style>
