<template>
  <v-card>
    <v-card-title>
      Nutrition
      <v-spacer></v-spacer>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>
    <v-data-table
      :headers="headers"
      :items="desserts"
      :search="search"
    ></v-data-table>
  </v-card>
  <!-- <div>
    <span>{{ dataTable }}</span
    ><br />

  </div> -->
</template>

<script>
import { Toolbar_language } from "@/website/language/Toolbar_language";
import { setting_language } from "@/website/language/setting_language";
import { feedback_language } from "@/website/language/feedback_language";
import { manage_customer_language } from "@/website/language/manage_customer_language";

export default {
  data: () => ({
    Toolbar_language: "",
    setting_language: "",
    feedback_language: "",
    manage_customer_language: "",
    dataTable: [],
    dataTableTH: [],
    dataTableEN: [],
    dataTableCH: [],
    dataTableKEY: [],
    search: "",
    headers: [
      {
        text: "Dessert (100g serving)",
        align: "start",
        sortable: false,
        value: "name",
      },
      { text: "Calories", value: "calories" },
      { text: "Fat (g)", value: "fat" },
      { text: "Carbs (g)", value: "carbs" },
      { text: "Protein (g)", value: "protein" },
      { text: "Iron (%)", value: "iron" },
    ],
    desserts: [
      {
        name: "Frozen Yogurt",
        calories: 159,
        fat: 6.0,
        carbs: 24,
        protein: 4.0,
        iron: "1%",
      },
      {
        name: "Ice cream sandwich",
        calories: 237,
        fat: 9.0,
        carbs: 37,
        protein: 4.3,
        iron: "1%",
      },
      {
        name: "Eclair",
        calories: 262,
        fat: 16.0,
        carbs: 23,
        protein: 6.0,
        iron: "7%",
      },
      {
        name: "Cupcake",
        calories: 305,
        fat: 3.7,
        carbs: 67,
        protein: 4.3,
        iron: "8%",
      },
      {
        name: "Gingerbread",
        calories: 356,
        fat: 16.0,
        carbs: 49,
        protein: 3.9,
        iron: "16%",
      },
      {
        name: "Jelly bean",
        calories: 375,
        fat: 0.0,
        carbs: 94,
        protein: 0.0,
        iron: "0%",
      },
      {
        name: "Lollipop",
        calories: 392,
        fat: 0.2,
        carbs: 98,
        protein: 0,
        iron: "2%",
      },
      {
        name: "Honeycomb",
        calories: 408,
        fat: 3.2,
        carbs: 87,
        protein: 6.5,
        iron: "45%",
      },
      {
        name: "Donut",
        calories: 452,
        fat: 25.0,
        carbs: 51,
        protein: 4.9,
        iron: "22%",
      },
      {
        name: "KitKat",
        calories: 518,
        fat: 26.0,
        carbs: 65,
        protein: 7,
        iron: "6%",
      },
    ],
  }),
  async created() {
    this.Toolbar_language = Toolbar_language;
    this.setting_language = setting_language;
    this.feedback_language = feedback_language;
    this.manage_customer_language = manage_customer_language;

    this.pushArry_TH();
   
    // this.pushArry_EN();
    // this.pushArry_CH();

    // for (const [key, value] of Object.entries(this.test)) {
    //   console.log(`${key}: ${value}`);
    //   this.test3.push(value);
    // }
  },
  methods: {
    async getArry(parameter, language) {
      for (const [key, value] of Object.entries(parameter[language] ) ) {
        
        if (language == "TH") {
          this.dataTableTH.push(value);
           this.dataTableTH.push(key);
        } else if (language == "EN") {
          this.dataTableEN.push(value);
        } else  {
          this.dataTableCH.push(value);
        }
      }
      // console.log("dataTableTH", this.dataTableTH);
      // console.log("dataTableEN", this.dataTableEN);
      // console.log("dataTableCH", this.dataTableCH);
      // console.log("dataTableKEY", this.dataTableKEY);
     

    },
  
    async pushArry_TH() {
      this.getArry(this.Toolbar_language, "TH");
      this.getArry(this.setting_language, "TH");
      this.getArry(this.feedback_language, "TH");
      this.getArry(this.manage_customer_language, "TH");
    },
    async pushArry_EN() {
      this.getArry(this.Toolbar_language, "EN");
      this.getArry(this.setting_language, "EN");
      this.getArry(this.feedback_language, "EN");
      this.getArry(this.manage_customer_language, "EN");
    },
    async pushArry_CH() {
      this.getArry(this.Toolbar_language, "CH");
      this.getArry(this.setting_language, "CH");
      this.getArry(this.feedback_language, "CH");
      this.getArry(this.manage_customer_language, "CH");
    },
  },
};
</script>

<style>
</style>