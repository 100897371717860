<template>
    <div v-if="page_loading">
        <loading :page_loading="page_loading" />
    </div>
    <div v-else>
      <loading :loading="loading" />

      <!-- แสดงภาพ -->
      <v-dialog v-model="ShowPhoto" max-width="500">
        <v-card class="ma-0">
          <v-card-text>
            <v-row align="center">
              <v-col cols="12" class="text-center">
                <img class="hidden-md-and-up" :src="ShowPhotoLink" width="300">
                <img class="hidden-sm-and-down" :src="ShowPhotoLink" width="450">
              </v-col>
            </v-row>
          </v-card-text>
          <v-divider/>
          <v-card-actions>
            <v-btn text color="#1976d2" @click="Opentap(ShowPhotoLink)">{{use_language.newtab}}</v-btn>
            <v-spacer></v-spacer>
            <v-btn text color="#1976d2" @click="ShowPhoto = false">{{use_language.close}}</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-card elevation="1" class="v-card-bottom-30 hidden-xs-only">
        <v-card-title style="height: 60px;">
          <h4>{{use_language.setting}}</h4> <v-icon>mdi-chevron-right</v-icon><span class="font-weight-regular body-1">{{use_language.shop}}</span>
          <v-spacer/>
        </v-card-title>
      </v-card>

      <v-card elevation="1" class="v-card-bottom-30">
        <v-card-title>
          <v-icon size="20" color="teal darken-3">fa-address-card</v-icon> <span class="ml-3">{{use_language.shop_info}}</span>
          <v-spacer/>
          <v-btn :elevation="1" color="primary" class="pa-2" @click="shopDisibled = !shopDisibled">
            <v-icon size="16" class="pr-1">fa-edit</v-icon>
            {{use_language.edit}}
          </v-btn>
        </v-card-title>
        <v-divider/>
        <v-card-text >
          <v-form ref="updateShopForm" lazy-validation>
            <v-row row >
              <v-col cols="12" md="6" class="pb-2 pt-0 px-0">
                <v-row row >
                  <v-col  cols="12" md="4" class="py-0">
                    <h4>{{use_language.shop_name}} :</h4>
                  </v-col>
                  <v-col  cols="12" md="8" class="py-0">
                    <v-text-field
                      :value="ShopAddress.Name"
                      @change="value => ShopAddress.Name = value"
                      autocomplete="off"
                      ref="refShopName"
                      :rules="[ v => !!v || 'กรุณาระบุ', ]"
                      outlined
                      hide-details
                      dense
                      :disabled="shopDisibled"
                      v-if="shopDisibled== false"
                    ></v-text-field>
                    <span v-else>{{ ShopAddress.Name }}</span>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row row >
              <v-col  cols="12" md="6" class="pb-2 pt-0 px-0">
                <v-row row >
                  <v-col  cols="12" md="4" class="py-0">
                    <h4>{{use_language.email}} :</h4>
                  </v-col>
                  <v-col  cols="12" md="8" class="py-0">
                    <v-text-field
                      :value="ShopAddress.Email"
                      @change="value => ShopAddress.Email = value"
                      autocomplete="off"
                      ref="refShopEmail"
                      :rules="[ v => !!v || 'กรุณาระบุ', ]"
                      outlined
                      hide-details
                      dense
                      :disabled="shopDisibled"
                      v-if="shopDisibled== false"
                    ></v-text-field>
                    <span v-else>{{ ShopAddress.Email }}</span>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row row >
              <v-col  cols="12" md="12" class="pb-2 pt-0 px-0">
                <v-row row >
                  <v-col  cols="12" md="2" class="py-0">
                    <h4>{{use_language.create_order}} :</h4>
                  </v-col>
                  <v-col  cols="12" md="10" class="py-0">
                    <span v-if="shopDisibled">{{ OrderActiveLessthanzero }}</span>
                    <v-radio-group v-else row v-model="OrderActiveLessthanzero">
                      <v-radio v-for="i in OrderActiveLessthanzeroItem" color="info" v-bind:key="i['value']" :label="i['label']" :value="i['value']"></v-radio>
                    </v-radio-group>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row row >
            </v-row>
            <v-row row v-if="shopDisibled == false">
              <v-col  cols="12" md="12" class="pb-2 pt-0 px-0">
                <v-row row >
                  <v-col  cols="12" md="12" align="left" class="py-0">
                    <v-btn dark color="primary" @click="UpdateShopAddress()" class="mr-1"><v-icon left >mdi-content-save</v-icon>  {{use_language.record}}</v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>

      <v-card elevation="1" class="v-card-bottom-30">
        <v-card-title>
        <v-icon size="20" color="primary darken-3">fa-map-marked-alt</v-icon><span class="ml-3">  {{use_language.tax_invoice_address}}</span>
        <v-spacer/>
        <v-btn @click="addShopAddressInvoice(), ShopAddressType= 'ADD'" dark color="primary"><v-icon left>mdi-plus</v-icon>   {{use_language.add_address}}</v-btn>
        </v-card-title>
        <v-divider/>
        <v-card-text>
          <v-row row>
            <v-col cols="12" md="12">
              <div style="max-height: 500px;" class="overflow-y-auto">
                <v-data-table :headers="headersShopAddressInvoice" :items="ShopAddressInvoiceData" class="elevation-0 packhai-border-table" hide-default-footer :items-per-page="10000">
                  <template v-slot:item.shopLogo="{ item }">
                    <a v-if="item.shopLogo!=null" @click="OpenPhoto(item.shopLogo)"><img :src="item.shopLogo" width="60" class="mt-1" /></a>
                  </template>
                  <template v-slot:item.fullAddress="{ item }">
                    {{ item.fullAddress }}
                    <v-chip v-if="item.isDefault" class="chip-set-13" color="primary" dark>  {{use_language.default}}  </v-chip>
                  </template>
                  <template v-slot:item.edit="{ item }">
                    <a @click="getShopAddressInvoiceForEdit(item), ShopAddressType = 'EDIT' "><v-icon size="16" color="orange darken-3">fa-edit</v-icon></a>
                  </template>
                  <template v-slot:item.delete="{ item }">
                    <a v-if="!item.isDefault" @click="deleteShopAddressInvoice(item.id)"><v-icon size="16" color="red darken-3">fa-trash-alt</v-icon></a>
                    <v-icon v-else size="16">fa-trash-alt</v-icon>
                  </template>
                </v-data-table>
              </div>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

      <v-card elevation="1" class="v-card-bottom-30">
        <v-card-title>
          <v-icon size="20" color="warning darken-3">fa-map-marked-alt</v-icon><span class="ml-3"> {{use_language.delivery_address}} </span>
          <v-spacer/>
          <v-btn @click="ShopAddressAction(null, 'ADD') " dark color="primary"><v-icon left>mdi-plus</v-icon>   {{use_language.add_address}} </v-btn>
        </v-card-title>
        <v-divider/>

        <v-card-text>
          <v-row row>
            <v-col  cols="12" md="12">
              <div style="max-height: 500px;" class="overflow-y-auto">
                <v-data-table :headers="headers1" :items="DataShopAddressManage" class="elevation-0 packhai-border-table" hide-default-footer :items-per-page="10000">
                  <template v-slot:item.fullAddress="{ item }">
                    {{ item.fullAddress }}
                    <v-chip v-if="item.isDefault" class="chip-set-13" color="primary" dark>  {{use_language.default}} </v-chip>
                  </template>
                  <template v-slot:item.edit="{ item }">
                    <a @click="ShopAddressAction(item, 'EDIT')"><v-icon size="16" color="orange darken-3">fa-edit</v-icon></a>
                  </template>
                  <template v-slot:item.delete="{ item }">
                    <a v-if="!item.isDefault" @click="ShopAddressAction(item, 'DELETE')"><v-icon size="16" color="red darken-3">fa-trash-alt</v-icon></a>
                    <v-icon v-else size="16">fa-trash-alt</v-icon>
                  </template>
                </v-data-table>
              </div>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

      <v-card elevation="1" class="v-card-bottom-30">
        <v-card-title>
          <v-icon size="20" color="orange darken-3">fa-money-check-alt</v-icon><span class="ml-3">{{use_language.bank_account}}</span>
          <v-spacer/>
          <v-btn @click="BankAcountAction(null, 'ADD')" dark color="primary"><v-icon left>mdi-plus</v-icon> {{use_language.default}} </v-btn>
        </v-card-title>
        <v-divider/>
        <v-card-text>
          <v-row row>
            <v-col cols="12" md="12">
              <div style="max-height: 500px;" class="overflow-y-auto">
                <v-data-table :headers="headersBankAcount" :items="ShopBankAcountData" class="elevation-0 packhai-border-table" hide-default-footer :items-per-page="10000">

                  <template v-slot:item.FullBankAcount="{ item }">
                    <a v-if="item.logo!=null" @click="OpenPhoto(item.logo)"><v-avatar size="25px" class="mr-2"><img :src="item.logo"/></v-avatar></a>
                    {{ item.bankName+' '+item.accountNumber+' '+item.accountName }}
                    <v-chip v-if="item.isDefault" class="chip-set-13" color="primary" dark> {{use_language.default}} </v-chip>
                  </template>
                  <template v-slot:item.isActive="{ item }">
                    <v-row>
                      <v-col cols="12" class="text-left">
                        <span v-if="item.isActive"><v-icon size="10" color="success">mdi-checkbox-blank-circle</v-icon> {{use_language.enable}}</span>
                        <span v-else><v-icon size="10" color="danger">mdi-checkbox-blank-circle</v-icon> {{use_language.disable}}</span>
                      </v-col>
                    </v-row>
                  </template>
                  <template v-slot:item.edit="{ item }">
                    <a @click="BankAcountAction(item, 'EDIT')"><v-icon size="16" color="orange darken-3">fa-edit</v-icon></a>
                  </template>
                  <template v-slot:item.delete="{ item }">
                    <a v-if="!item.isDefault" @click="BankAcountAction(item, 'DELETE')"><v-icon size="16" color="red darken-3">fa-trash-alt</v-icon></a>
                    <v-icon v-else size="16">fa-trash-alt</v-icon>
                  </template>
                </v-data-table>
              </div>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

      <v-card elevation="1" class="v-card-bottom-30">
        <v-card-title>
          <v-icon size="20" color="yellow darken-3">fa-cog</v-icon><span class="ml-3">{{use_language.open_order_by_customer_transfer}}</span>
          <v-spacer/>
          <v-btn :elevation="1" color="primary" class="pa-2" @click="addOrderModeDisibled = !addOrderModeDisibled">
            <v-icon size="16" class="pr-1">fa-edit</v-icon>
            {{use_language.edit}}
          </v-btn>
        </v-card-title>
        <v-divider/>
        <v-card-text>
          <v-row row >
            <v-col  cols="12" md="12">
              <v-radio-group
                v-model="AddOrderMode"
                column
                :disabled="addOrderModeDisibled"
              >
                <v-radio
                  label='เปิดออเดอร์แล้ว ให้สถานะไปที่ "รอแพ็ค"'
                  value="1"
                ></v-radio>
                <v-radio
                  label='เปิดออเดอร์แล้ว ให้สถานะไปที่ "รอตรวจสอบยอด"'
                  value="2"
                ></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
          <v-row row v-if="addOrderModeDisibled == false">
            <v-col  cols="12" md="12" class="py-0 px-0">
              <v-row row >
                <v-col  cols="12" md="12" align="left" class="py-0">
                  <v-btn dark color="primary" @click="UpdateAddOrderModeDisibled()" class="mr-1"><v-icon left >mdi-content-save</v-icon> {{use_language.record}}</v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

      <v-card elevation="1" class="v-card-bottom-30">
        <v-card-title>
          <v-icon size="24" color="success darken-3">mdi-file-cog-outline </v-icon>
          <span class="ml-3">{{use_language.set_tax_type_by_open_order}}</span>
          <v-spacer/>
          <v-btn :elevation="1" color="primary" class="pa-2" @click="VatTypeDisibled = !VatTypeDisibled">
            <v-icon size="16" class="pr-1">fa-edit</v-icon>
            {{use_language.edit}}
          </v-btn>
        </v-card-title>
        <v-divider/>
        <v-card-text>
          <v-row row >
            <v-col  cols="12" md="12">
              <v-radio-group
                v-model="VatType"
                column
                :disabled="VatTypeDisibled"
              >
                <v-radio
                  label='ไม่มีภาษี (No Vat)'
                  value="1"
                ></v-radio>
                <v-radio
                  label='รวมภาษี  (VAT Included)'
                  value="2"
                ></v-radio>
                <v-radio
                  label='ไม่รวมภาษี (VAT Excluded)'
                  value="3"
                ></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
          <v-row row v-if="VatTypeDisibled == false">
            <v-col  cols="12" md="12" class="py-0 px-0">
              <v-row row >
                <v-col  cols="12" md="12" align="left" class="py-0">
                  <v-btn dark color="primary" @click="UpdateVatType()" class="mr-1"><v-icon left >mdi-content-save</v-icon> {{use_language.record}}</v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

      <!-- <v-card elevation="1" class="v-card-bottom-30">
        <v-card-title>
          <v-icon size="24" color="red darken-3">fa-file-invoice-dollar </v-icon>
          <span class="ml-3">{{use_language.tax_type}}</span>
          <v-spacer/>
        </v-card-title>
        <v-divider/>
        <v-card-text>
          <v-row row >
            <v-col  cols="12" md="12">
              <v-radio-group
                v-model="AutoInvoiceType"
                column
                :disabled="true"
                v-if="AutoInvoiceType != null"
              >
                <v-radio
                  label='สร้างใบแจ้งหนี้และหักเงินจากบัญชีโดยสาขาแบบ Manual'
                  :value="1"
                ></v-radio>
                <v-radio
                  label='หักเงินจากบัญชีโดยอัตโนมัติทุกวัน ( ระบบไม่ทำการสร้างใบแจ้งหนี้รายวัน )'
                  :value="2"
                ></v-radio>
                <v-radio
                  label='หักเงินจากบัญชีโดยอัตโนมัติทุกวัน ( ระบบทำการสร้างใบแจ้งหนี้รายวัน )'
                  :value="3"
                ></v-radio>
              </v-radio-group>
              <span v-else>{{use_language.unspecified}}</span>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card> -->

      <v-card elevation="1" class="v-card-bottom-30">
        <v-card-title>
          <v-icon size="24" color="info darken-3">mdi-store </v-icon>
          <span class="ml-3">{{use_language.sales_channel}}</span>
          <v-spacer/>
          <v-btn dark color="primary" @click="ChannelSourceAction(null,'ADD')"><v-icon left>mdi-plus</v-icon> {{use_language.add_channel}} </v-btn>
        </v-card-title>
        <v-divider/>
        <v-card-text>
          <v-row row>
            <v-col cols="12" md="12">
              <div style="max-height: 500px;" class="overflow-y-auto">
                <v-data-table
                  :headers="headerschannelsource"
                  :items="Datatable_ChannelSource"
                  class="elevation-0 packhai-border-table"
                  hide-default-footer
                  :items-per-page="10000">
                  <template v-slot:item.logoPic="{ item }">
                    <a v-if="item.logoPic!=null" @click="OpenPhoto(item.logoPic)"><img :src="item.logoPic" width="60" class="mt-1" /></a>
                  </template>
                  <template v-slot:item.channelName="{ item }" >
                    <span >{{ item.channelName }}</span>
                  </template>
                  <template v-slot:item.created="{ item }">
                    {{item.created}}
                  </template>
                  <template v-slot:item.edit="{ item }">
                    <a @click="ChannelSourceAction(item, 'UPDATE')"><v-icon size="16" color="orange darken-3">fa-edit</v-icon></a>
                  </template>
                  <template v-slot:item.delete="{ item }">
                    <a @click="ChannelSourceAction(item, 'DELETE')"><v-icon size="16" color="red darken-3">fa-trash-alt</v-icon></a>
                  </template>
                </v-data-table>
              </div>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

     

      <v-dialog v-model="dialog_ExpressManage" :fullscreen="isFullscreen"  scrollable max-width="45%" persistent >
        <v-card>
          <v-card-title >
            <span>{{use_language.setting_express}}</span>
            <v-spacer></v-spacer>
            <v-btn icon  @click="dialog_ExpressManage = false, express_data = null"><v-icon color="danger">mdi-close</v-icon></v-btn>
          </v-card-title>
          <v-divider/>
          <v-card-text>
            <v-row>
              <v-col cols="12" md="3">
                <strong>{{ use_language.set_as_default }} :</strong>
              </v-col>
              <v-col cols="12" md="9">
                <v-checkbox
                  v-model="checkbox1"
                  readonly
              ></v-checkbox>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-btn color="primary"  @click="set_default_express(express_data)">{{ use_language.save }}</v-btn>
              </v-col>
            </v-row>


          </v-card-text>
        </v-card>
      </v-dialog>

      <v-dialog v-model="dialog_ShopAddressManage" :fullscreen="isFullscreen"  scrollable max-width="45%" persistent >
        <v-card>
          <v-card-title >
            <span v-if="ShopAddressType == 'EDIT'">{{use_language.edit_address}}</span>
            <span v-else-if="ShopAddressType == 'ADD'">{{use_language.add_address}}</span>
            <v-spacer></v-spacer>
            <v-btn icon  @click="dialog_ShopAddressManage = false, IsReadonly = false"><v-icon color="danger">mdi-close</v-icon></v-btn>
          </v-card-title>
          <v-divider/>
          <v-card-text>
            <v-row row >
              <v-col  cols="12" md="12" class="pb-0 pt-2 px-0">
                <v-row row >
                  <v-col  cols="12" md="3" class="py-0">
                  <h4> {{use_language.name_person_juristic}} :</h4>
                  </v-col>
                  <v-col  cols="12" md="9" class="py-0">
                    <v-text-field
                      :value="ShopAddressManage.Name"
                      @change="value => ShopAddressManage.Name = value"
                      autocomplete="off"
                      ref="refShopAddressManageName"
                      outlined
                      hide-details
                      dense
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row row >
              <v-col  cols="12" md="12" class="pb-0 pt-2 px-0">
                <v-row row >
                  <v-col  cols="12" md="3" class="py-0">
                    <h4>{{use_language.tel}} :</h4>
                  </v-col>
                  <v-col  cols="12" md="9" class="py-0">
                    <v-text-field
                      :value="ShopAddressManage.Phone"
                      @change="value => ShopAddressManage.Phone = value"
                      autocomplete="off"
                      ref="refShopAddressManagePhone"
                      maxlength="10"
                      outlined
                      hide-details
                      @keypress="isNumberWNoDot($event)"
                      dense
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>

            <v-row row >
              <v-col  cols="12" md="12" class="pb-0 pt-2 px-0">
                <v-row row >
                  <v-col  cols="12" md="3" class="py-0">
                  <h4>  {{use_language.address}} :</h4>
                  </v-col>
                  <v-col  cols="12" md="9" class="py-0">
                    <v-text-field
                      :value="ShopAddressManage.TextAddress"
                      @change="value => ShopAddressManage.TextAddress = value"
                      autocomplete="off"
                      ref="refShopAddressManageTextAddress"
                      outlined
                      hide-details
                      dense
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row row >
              <v-col  cols="12" md="12" class="pb-0 pt-2 px-0">
                <v-row row >
                  <v-col  cols="12" md="3" class="py-0">
                    <h4>{{use_language.zipcode}} :</h4>
                  </v-col>
                  <v-col  cols="12" md="9" class="py-0">
                    <v-autocomplete
                      v-model="ShopAddressManage.Address"
                      ref="refShopAddressManageAddress"
                      placeholder="รหัสไปรษณีย์ / จังหวัด / (เขต/อำเภอ) / (แขวง/ตำบล)"
                      :items="postcode_mapping"
                      :item-text="item => item.Postcode + ' ' + item.Province + ' ' + item.District + ' ' + item.SubDistrict"
                      outlined
                      required
                      return-object
                      hide-details
                      dense

                    ></v-autocomplete>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row row >
              <v-col  cols="12" md="12" class="pb-0 pt-2 px-0">
                <v-row row >
                  <v-col  cols="12" md="3" class="py-0">
                    <h4> {{use_language.set_default}}:</h4>
                  </v-col>
                  <v-col  cols="12" md="1" class="py-0">
                    <v-checkbox
                      :readonly="IsReadonly"
                      v-model="ShopAddressManage.IsDefault"
                    ></v-checkbox>
                  </v-col>
                  <v-col  cols="12" md="8">
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row>
              <v-col  cols="12" md="12" class="py-0 px-0">
                <v-row row >
                  <v-col  cols="12" md="12" align="left">
                    <v-btn dark color="primary" @click="ShopAddressActionConfirm()" class="mr-1"><v-icon left >mdi-content-save</v-icon> บันทึก</v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-dialog>

      <v-dialog v-model="dialog_ShopAddressInvoice" :fullscreen="isFullscreen"  scrollable max-width="45%" persistent >
        <v-card>
          <v-card-title >
            <span v-if="ShopAddressType == 'EDIT'" >{{use_language.edit_tax_invoice_address}}</span>
            <span v-else-if="ShopAddressType == 'ADD'" >{{use_language.add_tax_invoice_address}}</span>
            <v-spacer></v-spacer>
            <v-btn icon  @click="dialog_ShopAddressInvoice = false, IsReadonly = false, Photo.Photo_link=''"><v-icon color="danger">mdi-close</v-icon></v-btn>
          </v-card-title>
          <v-divider/>
          <v-card-text>
            <v-row row>
              <v-col class="pb-0 pt-2 px-0">
                <v-row row>
                  <v-col cols="12" md="3" class="py-0" >
                    <h4> รูป : </h4>
                  </v-col>
                  <v-col cols="12" md="9" class="py-0">
                    <a id="pick-avatar" >
                      <v-avatar
                        v-if="Photo.Photo_link!=null && Photo.Photo_link!=''"
                        class="sshoverpicture" size="90" tile >
                        <img :src="Photo.Photo_link"/>
                      </v-avatar>
                      <v-avatar v-else class="sshoverpicture" size="90" tile>
                        {{use_language.no_Picture}}
                      </v-avatar>
                    </a>
                    <avatar-cropper
                      @uploading="handleUploading"
                      @uploaded="handleUploaded"
                      @completed="handleCompleted"
                      @error="handlerError"
                      :cropper-options="cropperOptions"
                      :labels="textButton"
                      :upload-url="uploadurl"
                      trigger="#pick-avatar"
                    />
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row row >
              <v-col  cols="12" md="12" class="pb-0 pt-2 px-0">
                <v-row row >
                  <v-col  cols="12" md="3" class="py-0">
                  <h4>  {{use_language.name_person_juristic}} :</h4>
                  </v-col>
                  <v-col  cols="12" md="9" class="py-0">
                    <v-text-field
                      :value="ShopAddressInvoice.Name"
                      @change="value => ShopAddressInvoice.Name = value"
                      autocomplete="off"
                      ref="refShopAddressInvoiceName"
                      outlined
                      hide-details
                      dense
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row row >
              <v-col  cols="12" md="12" class="pb-0 pt-2 px-0">
                <v-row row >
                  <v-col  cols="12" md="3" class="py-0">
                    <h4>{{use_language.tax_invoice_number}} :</h4>
                  </v-col>
                  <v-col  cols="12" md="9" class="py-0">
                    <v-text-field
                      :value="ShopAddressInvoice.IDCardNumber"
                      @change="value => ShopAddressInvoice.IDCardNumber = value"
                      autocomplete="off"
                      ref="refShopAddressInvoiceIDCardNumber"
                      maxlength="13"
                      outlined
                      hide-details
                      @keypress="isNumberWNoDot($event)"
                      dense
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row row >
              <v-col  cols="12" md="12" class="pb-0 pt-2 px-0">
                <v-row row >
                  <v-col  cols="12" md="3" class="py-0">
                    <h4>{{use_language.tel}} :</h4>
                  </v-col>
                  <v-col  cols="12" md="9" class="py-0">
                    <v-text-field
                      :value="ShopAddressInvoice.Phone"
                      @change="value => ShopAddressInvoice.Phone = value"
                      autocomplete="off"
                      ref="refShopAddressInvoicePhone"
                      maxlength="10"
                      outlined
                      hide-details
                      @keypress="isNumberWNoDot($event)"
                      dense
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row row >
              <v-col  cols="12" md="12" class="pb-0 pt-2 px-0">
                <v-row row >
                  <v-col  cols="12" md="3" class="py-0">
                  <h4>  {{use_language.address}} :</h4>
                  </v-col>
                  <v-col  cols="12" md="9" class="py-0">
                    <v-text-field
                      :value="ShopAddressInvoice.TextAddress"
                      @change="value => ShopAddressInvoice.TextAddress = value"
                      autocomplete="off"
                      ref="refShopAddressInvoiceTextAddress"
                      outlined
                      hide-details
                      dense
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row row >
              <v-col  cols="12" md="12" class="pb-0 pt-2 px-0">
                <v-row row >
                  <v-col  cols="12" md="3" class="py-0">
                    <h4>{{use_language.zipcode}} :</h4>
                  </v-col>
                  <v-col  cols="12" md="9" class="py-0">
                    <v-autocomplete
                      v-model="ShopAddressInvoice.Address"
                      ref="refShopAddressInvoiceAddress"
                      placeholder="รหัสไปรษณีย์ / จังหวัด / (เขต/อำเภอ) / (แขวง/ตำบล)"
                      :items="postcode_mapping"
                      :item-text="item => item.Postcode + ' ' + item.Province + ' ' + item.District + ' ' + item.SubDistrict"
                      outlined
                      required
                      return-object
                      hide-details
                      dense
                    ></v-autocomplete>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row row >
              <v-col  cols="12" md="12" class="pb-0 pt-2 px-0">
                <v-row row >
                  <v-col  cols="12" md="3" class="py-0">
                    <h4> {{use_language.set_default}} :</h4>
                  </v-col>
                  <v-col  cols="12" md="1" class="py-0">
                    <v-checkbox
                      :readonly="IsReadonly"
                      v-model="ShopAddressInvoice.IsDefault"
                    ></v-checkbox>
                  </v-col>
                  <v-col  cols="12" md="8">
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row>
              <v-col  cols="12" md="12" class="pb-0 pt-2 px-0">
                <v-row row >
                  <v-col  cols="12" md="12" align="left" class="py-0">
                    <v-btn v-if="ShopAddressType == 'EDIT'" dark color="primary" @click="AddandEditShopAddressInvoice('EDIT')" class="mr-1"><v-icon left >mdi-content-save</v-icon> {{use_language.record}}</v-btn>
                    <v-btn v-else-if="ShopAddressType == 'ADD'" dark color="primary" @click="AddandEditShopAddressInvoice('ADD')" class="mr-1"><v-icon left >mdi-content-save</v-icon> {{use_language.record}}</v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-dialog>

      <v-dialog v-model="dialog_ShopBankAcount" :fullscreen="isFullscreen"  scrollable max-width="50%" persistent >
        <v-card >
          <v-card-title >
            <span v-if="ShopBankAcountType == 'EDIT'" >{{use_language.edit_bank}}</span>
            <span v-else-if="ShopBankAcountType == 'ADD'" >{{use_language.add_bankt}}</span>
            <v-spacer></v-spacer>
            <v-btn icon  @click="dialog_ShopBankAcount = false, IsReadonly = false"><v-icon color="danger">mdi-close</v-icon></v-btn>
          </v-card-title>
          <v-divider/>
          <v-card-text>
            <v-row>
              <v-col  cols="12" md="3" class="py-0">
                <h4> {{use_language.bank_name}} :</h4>
              </v-col>
              <v-col cols="12" md="9" class="py-0">
                <v-select
                  ref="BanksID"
                  :items="BankList"
                  v-model="BanksID"
                  item-key="text"
                  item-value="value"
                  dense
                  hide-details
                  outlined
                >
                <template slot="selection" slot-scope="data">
                  <v-avatar v-if="data.item.logo!=null" size="25px" class="mr-2"><img :src="data.item.logo"/></v-avatar>
                  {{ data.item.nameThai }}
                </template>
                <template slot="item" slot-scope="data">
                  <v-avatar v-if="data.item.logo!=null" size="25px" class="mr-2"><img :src="data.item.logo"/></v-avatar>
                  {{ data.item.nameThai }}
                </template>
                </v-select>
              </v-col>
            </v-row>
            <v-row row >
              <v-col  cols="12" md="12" class="pb-0 pt-2 px-0">
                <v-row row >
                  <v-col  cols="12" md="3" class="py-0">
                    <h4>{{use_language.bank_number}} :</h4>
                  </v-col>
                  <v-col  cols="12" md="9" class="py-0">
                    <v-text-field
                      :value="AccountNumber"
                      @change="value => AccountNumber = Trim_valueInt(value)"
                      ref="AccountNumber"
                      autocomplete="off"
                      outlined
                      dense hide-details
                      @keypress="isNumberWNoDot($event)"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row row >
              <v-col  cols="12" md="12" class="pb-0 pt-2 px-0">
                <v-row row >
                  <v-col  cols="12" md="3" class="py-0">
                    <h4>{{use_language.name_account}} :</h4>
                  </v-col>
                  <v-col  cols="12" md="9" class="py-0">
                    <v-text-field
                      :value="AccountName"
                      @change="value => AccountName = value"
                      autocomplete="off"
                      ref="AccountName"
                      maxlength="100"
                      outlined
                      hide-details
                      dense
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row row >
              <v-col  cols="12" md="12" class="pb-0 pt-2 px-0">
                <v-row row >
                  <v-col  cols="12" md="3" class="py-0">
                    <h4>{{use_language.status}} :</h4>
                  </v-col>
                  <v-col  cols="2" md="1" class="py-0">
                    <v-switch v-model="AccountIsActive" :readonly="IsReadonly" color="success" class="mx-2"></v-switch>
                  </v-col>
                  <v-col  cols="10" md="8" class="py-0">
                    <span v-if="AccountIsActive == 1">{{use_language.enable}}</span>
                    <span v-else>{{use_language.disable}}</span>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row row >
              <v-col  cols="12" md="12" class="pb-0 pt-2 px-0">
                <v-row row >
                  <v-col  cols="12" md="3" class="py-0">
                    <h4> {{use_language.set_default}} :</h4>
                  </v-col>
                  <v-col  cols="12" md="1" class="py-0">
                    <v-checkbox
                      :readonly="IsReadonly"
                      v-model="AccountIsDefault"
                    ></v-checkbox>
                  </v-col>
                  <v-col  cols="12" md="8"></v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row>
              <v-col  cols="12" md="12" class="pb-0 pt-2 px-0">
                <v-row row >
                  <v-col  cols="12" md="12" align="left" class="py-0">
                    <v-btn dark color="primary" @click="BankAcountActionConfirm()" class="mr-1"><v-icon left >mdi-content-save</v-icon> {{use_language.record}}</v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-dialog>

      <v-dialog v-model="dialog_ChannelSource " :fullscreen="isFullscreen"  scrollable max-width="60%" persistent >
         <v-card>
            <v-card-title >
              <span v-if="chanal_actions == 'UPDATE'" >แก้ไขช่องทางการขาย</span>
              <span v-else-if="chanal_actions == 'ADD'" >เพิ่มช่องทางการขาย</span>
              <v-spacer></v-spacer>
              <v-btn icon  @click="dialog_ChannelSource = false"><v-icon color="danger">mdi-close</v-icon></v-btn>
            </v-card-title>
            <v-divider/>
            <v-card-text>
              <div>
                <v-row >
                  <v-col cols="3" class="py-1">
                    <h3 class="fn-14"> {{use_language.picture}} : </h3>
                  </v-col>
                  <v-col cols="8" class="py-1">

                    <v-avatar
                      v-if="PhotoLink_pre!=null && input_chanalFileUpload == null"
                      class="sshoverpicture" size="90" tile >
                      <img :src="PhotoLink_pre" />
                    </v-avatar><v-file-input
                      v-model="input_chanalFileUpload"
                      ref="input_chanalFileUpload"
                      label="เลือกภาพ"
                      append-icon="mdi-camera"
                    ></v-file-input>
                  </v-col>
                </v-row>
                <v-row class="pb-0">
                  <v-col cols="3" class="pa-0"> </v-col>
                </v-row>
                <v-row class="pt-3">
                  <v-col cols="3" class="py-0 pt-1">
                    <h3 class="fn-14"> {{use_language.channel}} : </h3>
                  </v-col>
                  <v-col cols="8" class="py-0 pt-1">
                    <v-text-field
                      :value="input_chanalName"
                      @change="value => input_chanalName = value"
                      autocomplete="off"
                      ref="input_chanalName"
                      outlined
                      hide-details
                      dense
                    />
                  </v-col>
                </v-row>
                <v-row class=" mb-2" v-if="chanal_actions == 'ADD'">
                  <v-col cols="12" md="3" class="pb-0"></v-col>
                  <v-col cols="12" md="8" class="pb-0">
                    <v-btn @click="add_data_list_chanal()" color="primary">เพิ่มช่องทาง</v-btn>
                  </v-col>
                </v-row>
                <v-row v-if="chanal_actions == 'ADD'">
                  <v-col cols="0" md="3" class="pb-0"></v-col>
                  <v-col cols="12" md="8"  class="pb-0">

                    <div v-if="data_list_chanal.length == 0" align="left">
                      <h3>ไม่มีข้อมูล</h3>
                    </div>
                    <div v-else>
                      <v-simple-table class="packhai-border-table packhai-table">
                        <template v-slot:default>
                          <thead>
                            <tr>
                              <th class="text-center">รูป</th>
                              <th class="text-left">ช่องทาง</th>
                              <th class="text-center">ลบ</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              v-for="item in data_list_chanal"
                              :key="item.chanalName"
                            >
                              <td class="text-left">
                                <v-img style="margin:5px;"
                                  :src="item.photoLink"
                                  class="grey lighten-2"
                                  width="50"
                                  height="50"
                                  @click="OpenPhoto(item.photoLink, 0)"
                              ></v-img>
                              </td>
                              <td class="text-center">{{ item.chanalName }}</td>
                              <td class="text-center"><v-btn icon @click="delete_data_list_chanal(item.id)" color="primary"><v-icon color="red">fa-trash-alt</v-icon></v-btn></td>
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                    </div>
                  </v-col>
                </v-row>
              </div>
            </v-card-text>
            <v-card-actions>
              <v-btn v-if="chanal_actions == 'ADD'" dark color="primary" @click="upload_order_update_data_chanal('ADD')" class="mr-1">
                <v-icon left >mdi-content-save</v-icon> บันทึก
              </v-btn>
              <v-btn v-if="chanal_actions == 'UPDATE'" dark color="primary" @click="upload_order_update_data_chanal('UPDATE')" class="mr-1">
                <v-icon left >mdi-content-save</v-icon> อัพเดท
              </v-btn>
            </v-card-actions>
         </v-card>
      </v-dialog>
    </div>
</template>

<script>
    import Loading from '@/website/components/Loading'
    import axios from 'axios'
    import AvatarCropper from "vue-avatar-cropper";
    import { generalService_dotnet, globalService, shopService_dotnet } from '@/website/global'
    import { isNumberWNoDot, Trim_valueInt,get_languages } from '@/website/global_function'
 

    export default {
        components: {
            Loading,
            AvatarCropper
        },

        data: () => ({
          header_token:{'Content-Type': 'application/json', 'Authorization': 'Bearer '+localStorage.getItem('authorization_token')},
          // แสดงภาพ
          ShowPhoto: false,
          ShowPhotoLink: '',
         use_language: null,
          set_language: null,
          test123: "",
            page_loading: true,
            loading: false,
            // ข้อมูล Shop
            ShopAddress: {
              "ID":null,
              "Name": null,
              "Tel": null,
              "Address": null,
              "AddressCity": null,
              "Email": null,
              "PostcodeMappingID":null,
            },
            // เปิดออเดอร์ อนุญาติให้เปิดออเดอร์ได้สต๊อก=0
            OrderActiveLessthanzero: 0,
            OrderActiveLessthanzeroItem: [{ "label": "ไม่ให้เปิดออเดอร์ถ้าสต๊อกสินค้าไม่เพียงพอ", "value": 0 }, { "label": "เปิดออเดอร์ได้ในกรณีสต๊อกไม่เพียงพอ", "value": 1 }],

            // ที่อยู่จัดส่ง
            ShopAddressManage: {
              "ID":null,
              "Name": null,
              "Phone": null,
              "TextAddress": null,
              "Address": null,
              "PostcodeMappingID":null,
              "IsDefault": true,
            },

            // ออกใบกำกับภาษี
            ShopAddressInvoice: {
              "ID":null,
              "Name": null,
              "Phone": null,
              "IDCardNumber": null,
              "TextAddress": null,
              "Address": null,
              "PostcodeMappingID": null,
              "IsDefault": true,
              "Photo":''
            },

            DataShopAddressManage: [],
            ShopAddressInvoiceData: [],
            ShopBankAcountData: [],

            headers1: [
              // { text: 'ลำดับ', align: 'center', sortable: false, value: 'num' },
              { text: 'ที่อยู่จัดส่ง', align: 'left', value: 'fullAddress', sortable: false },
              { text: 'แก้ไข', align: 'center', value: 'edit', sortable: false , width: '100px'},
              { text: 'ลบ', align: 'center', value: 'delete', sortable: false , width: '100px'},
              // { text: '', align: 'center', value: 'setDefault', sortable: false , width: '100px' },
            ],

            headersShopAddressInvoice: [
              // { text: 'ลำดับ', align: 'center', sortable: false, value: 'num' },
              { text: 'รูป', align: 'center', value: 'shopLogo', sortable: false, width: '100px'},
              { text: 'ที่อยู่ใบกำกับภาษี', align: 'left', value: 'fullAddress', sortable: false },
              { text: 'แก้ไข', align: 'center', value: 'edit', sortable: false , width: '100px'},
              { text: 'ลบ', align: 'center', value: 'delete', sortable: false , width: '100px'},
              // { text: '', align: 'center', value: 'setDefault', sortable: false , width: '100px' },
            ],

            headersBankAcount: [
              // { text: 'ลำดับ', align: 'center', sortable: false, value: 'num' },
              { text: 'บัญชีธนาคาร', align: 'left', value: 'FullBankAcount', sortable: false },
              { text: 'สถานะ', align: 'center', value: 'isActive', sortable: false , width: '200px'},
              { text: 'แก้ไข', align: 'center', value: 'edit', sortable: false , width: '100px'},
              { text: 'ลบ', align: 'center', value: 'delete', sortable: false , width: '100px'},
              // { text: '', align: 'center', value: 'setDefault', sortable: false , width: '100px' },
            ],

            dialog_ShopAddressManage: false,
            dialog_ShopBankAcount: false,
            dialog_ShopAddressInvoice: false,
            ShopAddressType : null,
            ShopBankAcountType : null,
            AutoInvoiceType: null ,
            BankID: null,
            AddressManageID: null,
            ShopAddressManageMinID : null,
            isFullscreen : false,
            shopDisibled : true,
            IsReadonly : false,
            rulesTel: [],
            BankList: [],
            // this.rulesTel = [v => !!v && v.length >= 9 && v.length <= 10 || 'เบอร์โทรตัวเลข 9-10 ตัว']
            // postcode_mapping: PostcodeMapping,
            postcode_mapping: [],

            // shopBank
            BanksID: null,
            BanksName : null,
            AccountNumber: null,
            AccountName: null,
            AccountIsActive: true,
            AccountIsDefault: true,

            // addordermode
            AddOrderMode: null,
            AddOrderMode_tmp: null,
            addOrderModeDisibled: true,

            // vat
            VatType:null,
            VatTypeDisibled:true,

            // chanelsource
            dialog_ChannelSource: false,
            ChannelSourcenameValid:false,
            ChannelSourcePhotoValid:false,
            ChannelSourcenameValidText:null,
            ChannelSourcePhotoValidText:null,
            Data_ChannelSource:{
              "ID":null,
              "Action":null,
              "ShopID":localStorage.getItem("shop_id"),
              "Name":null,
              "Photo":'',
              "Ischannelsource":'true'
            },
            headerschannelsource: [
              { text: 'รูป', align: 'center', value: 'logoPic', sortable: false, width: '100px'},
              { text: 'ช่องทาง', align: 'left', value: 'channelName', sortable: false , width: '400px'},
              { text: 'วันที่', align: 'center', value: 'created', sortable: false, width: '200px' },
              { text: 'แก้ไข', align: 'center', value: 'edit', sortable: false , width: '100px'},
              { text: 'ลบ', align: 'center', value: 'delete', sortable: false , width: '100px'},
            ],
            Datatable_ChannelSource:[],
            // AvatarCropper
            Photo:{
              "Photo_link": null,
              "PhotoLinkUpdate": {
                "file":[],
                "fileSize":0,
                "from_Picture":{},
                "from_path":''
              },
            },
            cropperOptions:{
              background:false,
              viewMode:1,
              aspectRatio: 1,
              autoCropArea: 1,
              movable: false,
              zoomable: false
            },
            textButton: {
              submit:"ตกลง"
              ,cancel:"ยกเลิก"
            },
            uploadurl: globalService+"uploadPicture",
            message: "ready",


            // manage chanal
            data_list_chanal : [],
            input_chanalFileUpload : null,
            PhotoLink_pre : null,
            input_chanalName : null,
            chanal_actions : null,

            // express
            headersExpress : [
              { text: 'รูป', align: 'center', value: 'logoPic', sortable: false, width: '100px'},
              { text: 'ขนส่ง', align: 'left', value: 'expressCompanyName', sortable: false , width: '400px'},
              // { text: 'สถานะ', align: 'center', value: 'status', sortable: false, width: '200px' },
              { text: 'แก้ไข', align: 'center', value: 'action', sortable: false , width: '50px'},
            ],
            data_express: [],

            img_chanal_valid: false,

            dialog_ExpressManage: false,
            checkbox1 : true,
            express_data: null,

        }),

        computed: {

        },

        watch: {
          shopDisibled(){
            if (!this.shopDisibled){
              this.getCreateOrderValue()
            }else{
              this.getCreateOrderLabel()
            }
          },
        },

        async created() {
            // document.title = this.$router.history.current.meta.title
            // this.shop_id = localStorage.getItem("shop_id")
            // this.staff_id = localStorage.getItem("staff_id")



     //เก็บภาษามาไว้ใน use_language
         this.use_language = await this.get_languages(localStorage.getItem("set_language"));


            await this.get_postcode_mapping()


            await this.load_data()


            // await this.get_bank_setting()


            // await this.data_shop()

            // await this.get_data_manage_shop_address()

            // await this.getDataShopAddressInvoice()

            // await this.getDataBankAcount()

            // await this.get_shop_channelSource_setting()


            this.page_loading = false
        },

        methods: {
          isNumberWNoDot,
          Trim_valueInt,
          get_languages,
          async load_data () {

            this.loading = true
            await axios.all([
              axios.post(shopService_dotnet+'ShopSetting/get-shop-setting', {'ShopID': localStorage.getItem("shop_id")}, {headers: this.header_token}),
              axios.post(shopService_dotnet+'ShopSetting/get-banks-setting', {}, {headers: this.header_token} ),
              axios.post(shopService_dotnet+'ShopAddress/get-shop-address-list', {'shopID': localStorage.getItem("shop_id"),'isAscending': true, 'orderBy': 1}, {headers: this.header_token} ),
              axios.post(shopService_dotnet+'ShopAddressInvoice/get-shop-address-invoice-list', {'shopID': localStorage.getItem("shop_id"), 'isAscending': true, 'orderBy': 1}, {headers: this.header_token} ),
              axios.post(shopService_dotnet+'ShopSetting/get-shop-bank-acount-setting', {'shopID': localStorage.getItem("shop_id")}, {headers: this.header_token} ),
              axios.post(shopService_dotnet+'ShopSetting/get-shop-channelSource-setting', {'shopID': localStorage.getItem("shop_id")}, {headers: this.header_token}),

            ]).then(axios.spread((res_Shop, res_Banks, res_ShopAddress, res_ShopAddressInvoice, res_BankAcount, res_chanals) => {
              // data shop
                  this.ShopAddress.Name = res_Shop.data.name
                  this.ShopAddress.Tel = res_Shop.data.phone
                  this.ShopAddress.Email = res_Shop.data.email
                  this.AutoInvoiceType = res_Shop.data.autoInvoiceType
                  this.OrderActiveLessthanzero = res_Shop.data.isAllowAddOrderWhenStockShopNotAvailable

                  this.getCreateOrderLabel()
                  if( res_Shop.data.addOrderMode != null ){
                    this.AddOrderMode = res_Shop.data.addOrderMode
                    this.AddOrderMode = this.AddOrderMode.toString()

                    this.AddOrderMode_tmp = res_Shop.data.addOrderMode
                    this.AddOrderMode_tmp = this.AddOrderMode_tmp.toString()
                  }

                  if(res_Shop.data.defaultVatType != null){
                    this.VatType = res_Shop.data.defaultVatType
                    this.VatType = this.VatType.toString()
                  }
              // data banks
                  this.BankList = res_Banks.data;

              // Shop Address
                  if( res_ShopAddress.data.length > 0){
                    this.DataShopAddressManage = res_ShopAddress.data
                    this.ShopAddressManageMinID = res_ShopAddress.data[0].id
                  }
              // Shop Address invoice
                  if(res_ShopAddressInvoice.data.length > 0) {
                    this.ShopAddressInvoiceData = res_ShopAddressInvoice.data
                  } else {
                    this.ShopAddressInvoiceData = []
                  }

              // bank Acount
                  if( res_BankAcount.data.length > 0){
                    this.ShopBankAcountData = res_BankAcount.data
                  }
              // chanal
                  this.Datatable_ChannelSource = res_chanals.data


            }));

            this.loading = false

          },

          editExpress(item){
            this.dialog_ExpressManage = true
            this.express_data = item
          },

          async data_shop () {

            this.loading = true
            let response = await axios.post(shopService_dotnet+'ShopSetting/get-shop-setting', {
              'ShopID': localStorage.getItem("shop_id")
            }, {headers: this.header_token} )

            this.ShopAddress.Name = response.data.name
            this.ShopAddress.Tel = response.data.phone
            this.ShopAddress.Email = response.data.email
            this.AutoInvoiceType = response.data.autoInvoiceType
            this.OrderActiveLessthanzero = response.data.isAllowAddOrderWhenStockShopNotAvailable

            await this.getCreateOrderLabel()
            // await this.getCreateOrderValue()
            // addorderMode
            if( response.data.addOrderMode != null ){
              this.AddOrderMode = response.data.addOrderMode
              this.AddOrderMode = this.AddOrderMode.toString()

              this.AddOrderMode_tmp = response.data.addOrderMode
              this.AddOrderMode_tmp = this.AddOrderMode_tmp.toString()
            }

            if(response.data.defaultVatType != null){
              this.VatType = response.data.defaultVatType
              this.VatType = this.VatType.toString()
            }

            this.loading = false

          },

          async get_bank_setting  () {
            let response = await axios.post(shopService_dotnet+'ShopSetting/get-banks-setting', {}, {headers: this.header_token} )
            this.BankList = response.data;
          },

          async get_data_manage_shop_address () {
            this.loading = true
            let response = await axios.post(shopService_dotnet+'ShopAddress/get-shop-address-list', {
              'shopID': localStorage.getItem("shop_id"),
              'isAscending': true,
              'orderBy': 1
            }, {headers: this.header_token} )

            if( response.data.length > 0){
              this.DataShopAddressManage = response.data
              this.ShopAddressManageMinID = response.data[0].id
            }
            this.loading = false
          },

          async get_shop_channelSource_setting () {
            this.loading = true
            let response = await axios.post(shopService_dotnet+'ShopSetting/get-shop-channelSource-setting', {
              'shopID': localStorage.getItem("shop_id")
            }, {headers: this.header_token} )

            this.Datatable_ChannelSource = response.data
            this.loading = false
          },

          getCreateOrderLabel(){
            if (this.OrderActiveLessthanzero==0){ this.OrderActiveLessthanzero = this.OrderActiveLessthanzeroItem[0]['label'] }
            else{ this.OrderActiveLessthanzero = this.OrderActiveLessthanzeroItem[1]['label'] }
          },
          getCreateOrderValue(){
            if (this.OrderActiveLessthanzero==this.OrderActiveLessthanzeroItem[0]['label']){ this.OrderActiveLessthanzero = this.OrderActiveLessthanzeroItem[0]['value'] }
            else{ this.OrderActiveLessthanzero = this.OrderActiveLessthanzeroItem[1]['value'] }
          },

          async UpdateShopAddress () {
            if (this.$refs.updateShopForm.validate()) {
              try {
                let response = await axios.post(shopService_dotnet+'ShopSetting/update-shop-setting', {
                  'id': localStorage.getItem("shop_id"),
                  'name': this.ShopAddress.Name,
                  'phone': null,
                  'email': this.ShopAddress.Email,
                  'balanceLimit': null,
                  'addOrderMode': null,
                  'customerType': null,
                  'isHideAddress': true,
                  'defaultExpressCompanyID': null,
                  'autoInvoiceType': null,
                  'isNeedImportFile': true,
                  'isAllowAddOrderWhenStockBranchNotAvailable': null,
                  'isAllowAddOrderWhenStockShopNotAvailable': this.OrderActiveLessthanzero == 0 ? false : true ,
                  'defaultVatType': null,
                  'serviceRateVatType': null,
                  'referenceCode': 'string',
                  'isVrichUseOwnExpress': null,
                  'token': null,
                  'autoCalculateSpaceDate': null

                }, {headers: {'Content-Type': 'application/json', 'Authorization': 'Bearer ' + localStorage.getItem('token')}} )
                this.shopDisibled = true
                await this.data_shop()
              } catch (e) {
                  this.$swal({
                    type: 'warning',
                    title: 'มีข้อผิดพลาดบางอย่าง กรุณาลองใหม่อีกครั้ง!!',
                  })
              }

            }
          },

          async ShopAddressAction (item, type) {
            if (type == 'ADD') {
              this.clearDataAddressManage()
              this.dialog_ShopAddressManage = true
              this.ShopAddressType = type
              this.AddressManageID = null
            } else if (type == 'EDIT') {
              await this.getDataAddressManageID(item)
              this.ShopAddressType = type
              this.AddressManageID = item.id

            } else if (type == 'DELETE') {
              this.ShopAddressType = type
              this.$swal({
                position: "top",
                type: 'warning',
                title: 'ลบ ใช่หรือไม่ !!',
                confirmButtonText: 'ยืนยัน',
                cancelButtonText: 'ยกเลิก',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                allowOutsideClick: false
              }).then(async(result) => {
                if (result.value) {
                  this.ShopAddressType = type
                  this.AddressManageID = item.id
                  this.ShopAddressActionConfirm()
                }
              })
            }
          },

          async ShopAddressActionConfirm () {
            if(this.ShopAddressType == 'ADD'){

              if(this.ShopAddressManage.Name == null || this.ShopAddressManage.Name == ''){
                this.$refs.refShopAddressManageName.focus()
                return
              } else if (this.ShopAddressManage.Phone == null || this.ShopAddressManage.Phone == '') {
                this.$refs.refShopAddressManagePhone.focus()
                return
              } else if (this.ShopAddressManage.TextAddress == null || this.ShopAddressManage.TextAddress == '') {
                this.$refs.refShopAddressManageTextAddress.focus()
                return
              } else if (this.ShopAddressManage.Address == null || this.ShopAddressManage.Address == '') {
                this.$refs.refShopAddressManageAddress.focus()
                return
              }

              try {
                this.loading = true
                let response = await axios.post(shopService_dotnet+'ShopAddress/update-shop-address', {
                    "id": 0,
                    "shopId": localStorage.getItem("shop_id"),
                    "name": this.ShopAddressManage.Name,
                    "phone": this.ShopAddressManage.Phone,
                    "address": this.ShopAddressManage.TextAddress,
                    "postcodeMappingId": this.ShopAddressManage.Address.Id,
                    "tumbon": this.ShopAddressManage.Address.district,
                    "amphoe": this.ShopAddressManage.Address.amphoe,
                    "province": this.ShopAddressManage.Address.province,
                    "postcode": this.ShopAddressManage.Address.zipcode,
                    "isDefault": this.ShopAddressManage.IsDefault,
                    "isDeleted": false,
                    "bestsystemCustomerId": null
                }, {headers: this.header_token} )
                this.$swal({
                  toast: true,
                  timer: 1500,
                  showConfirmButton: false,
                  position: 'top',
                  type: 'success',
                  title: 'ดำเนินการเรียบร้อย'
                })
                  this.dialog_ShopAddressManage = false
                  this.loading = false
                this.get_data_manage_shop_address()
              } catch (e) {
                this.$swal({
                  type: 'warning',
                  title: 'มีข้อผิดพลาดบางอย่าง กรุณาลองใหม่อีกครั้ง!!',
                })
                this.loading = false
              }


            } else if (this.ShopAddressType == 'EDIT') {
              if(this.ShopAddressManage.Name == null || this.ShopAddressManage.Name == ''){
                this.$refs.refShopAddressManageName.focus()
                return
              } else if (this.ShopAddressManage.Phone == null || this.ShopAddressManage.Phone == '') {
                this.$refs.refShopAddressManagePhone.focus()
                return
              } else if (this.ShopAddressManage.TextAddress == null || this.ShopAddressManage.TextAddress == '') {
                this.$refs.refShopAddressManageTextAddress.focus()
                return
              } else if (this.ShopAddressManage.Address == null || this.ShopAddressManage.Address == '') {
                this.$refs.refShopAddressManageAddress.focus()
                return
              }

              try {
                this.loading = true
                let response = await axios.post(shopService_dotnet+'ShopAddress/update-shop-address', {
                    "id": this.ShopAddressManage.ID,
                    "shopId": localStorage.getItem("shop_id"),
                    "name": this.ShopAddressManage.Name,
                    "phone": this.ShopAddressManage.Phone,
                    "address": this.ShopAddressManage.TextAddress,
                    "postcodeMappingId": this.ShopAddressManage.Address.Id,
                    "tumbon": this.ShopAddressManage.Address.district,
                    "amphoe": this.ShopAddressManage.Address.amphoe,
                    "province": this.ShopAddressManage.Address.province,
                    "postcode": this.ShopAddressManage.Address.zipcode,
                    "isDefault": this.ShopAddressManage.IsDefault,
                    "isDeleted": false,
                    "bestsystemCustomerId": null
                }, {headers: this.header_token} )
                this.$swal({
                  toast: true,
                  timer: 1500,
                  showConfirmButton: false,
                  position: 'top',
                  type: 'success',
                  title: 'ดำเนินการเรียบร้อย'
                })
                  this.dialog_ShopAddressManage = false
                  this.IsReadonly = false
                  this.loading = false
                  this.get_data_manage_shop_address()
              } catch (e) {
                this.$swal({
                  type: 'warning',
                  title: 'มีข้อผิดพลาดบางอย่าง กรุณาลองใหม่อีกครั้ง!!',
                })
                this.loading = false
              }
            } else if (this.ShopAddressType == 'DELETE' ) {


              try {
                this.loading = true
                let response = await axios.post(shopService_dotnet+'shopAddress/delete-shop-address', {
                  'id': this.AddressManageID
                }, {headers: this.header_token} )
                this.$swal({
                  toast: true,
                  timer: 1500,
                  showConfirmButton: false,
                  position: 'top',
                  type: 'success',
                  title: 'ลบเรียบร้อย'
                })
                this.loading = false
                this.get_data_manage_shop_address()
              } catch (e) {
                this.$swal({
                  type: 'warning',
                  title: 'มีข้อผิดพลาดบางอย่าง กรุณาลองใหม่อีกครั้ง!!',
                })
                this.loading = false
              }
            }

          },

          async getDataAddressManageID (item) {
            // var ShopAddressManage = response.data.resultShopAddress[0]
            this.ShopAddressManage.ID = item.id
            this.ShopAddressManage.Name = item.name
            this.ShopAddressManage.Phone = item.phone
            this.ShopAddressManage.TextAddress = item.address
            this.ShopAddressManage.IsDefault = item.isDefault
            this.ShopAddressManage.Address = {
                "Id": item.postcodeMappingId,
                "SubDistrict": item['subDistrict'],
                "District": item['district'],
                "Province": item['province'],
                "Postcode": item['postcode'],
                "ProvinceId": item['provinceId']
            }

            if(this.ShopAddressManage.IsDefault == true) {
              this.IsReadonly = true
            }

            this.dialog_ShopAddressManage = true

          },

          async getDataShopAddressInvoice () {

            try {
              this.loading = true
              let response = await axios.post(shopService_dotnet+'ShopAddressInvoice/get-shop-address-invoice-list', {
                'shopID': localStorage.getItem("shop_id"),
                'isAscending': true,
                'orderBy': 1
              }, {headers: this.header_token} )

              if(response.data.length > 0) {
                this.ShopAddressInvoiceData = response.data
              } else {
                this.ShopAddressInvoiceData = []
              }

              this.loading = false

            } catch (e) {
              this.$swal({
                type: 'warning',
                title: 'มีข้อผิดพลาดบางอย่าง กรุณาลองใหม่อีกครั้ง!!',
              })
              this.loading = false
            }

          },

          addShopAddressInvoice () {
            this.clearDataAddressInvoice()
            this.Data_ChannelSource.Ischannelsource=false
            this.dialog_ShopAddressInvoice = true
          },

          async AddandEditShopAddressInvoice (type) {
            if(this.ShopAddressInvoice.Name == null || this.ShopAddressInvoice.Name == ''){
              this.$refs.refShopAddressInvoiceName.focus()
              return
            } else if (this.ShopAddressInvoice.Phone == null || this.ShopAddressInvoice.Phone == '') {
              this.$refs.refShopAddressInvoicePhone.focus()
              return
            } else if (this.ShopAddressInvoice.TextAddress == null || this.ShopAddressInvoice.TextAddress == '') {
              this.$refs.refShopAddressInvoiceTextAddress.focus()
              return
            } else if (this.ShopAddressInvoice.Address == null || this.ShopAddressInvoice.Address == '') {
              this.$refs.refShopAddressInvoiceAddress.focus()
              return
            }

            if(type == 'ADD'){
              try {
                this.loading = true
                let response = await axios.post(shopService_dotnet+'ShopAddressInvoice/update-shop-address-invoice', {
                    "id": 0,
                    "shopId": localStorage.getItem("shop_id"),
                    "name": this.ShopAddressInvoice.Name,
                    "idcardNumber": this.ShopAddressInvoice.IDCardNumber,
                    "phone": this.ShopAddressInvoice.Phone,
                    "address": this.ShopAddressInvoice.TextAddress,
                    "postcodeMappingId": this.ShopAddressInvoice.Address.Id,
                    "shopLogo": this.ShopAddressInvoice.Photo != "" ? this.ShopAddressInvoice.Photo : null ,
                    "isDefault": this.ShopAddressInvoice.IsDefault,
                    "isDeleted": false,
                }, {headers: this.header_token} )
                this.$swal({
                  toast: true,
                  timer: 1500,
                  showConfirmButton: false,
                  position: 'top',
                  type: 'success',
                  title: 'ดำเนินการเรียบร้อย'
                })
                  this.dialog_ShopAddressInvoice = false
                  this.loading = false
                this.getDataShopAddressInvoice()
              } catch (e) {
                this.$swal({
                  type: 'warning',
                  title: 'มีข้อผิดพลาดบางอย่าง กรุณาลองใหม่อีกครั้ง!!',
                })
                this.loading = false
              }
            } else if (type == 'EDIT') {
              try {
                this.loading = true
                let response = await axios.post(shopService_dotnet+'ShopAddressInvoice/update-shop-address-invoice', {
                    "id": this.ShopAddressInvoice.ID,
                    "shopId": localStorage.getItem("shop_id"),
                    "name": this.ShopAddressInvoice.Name,
                    "idcardNumber": this.ShopAddressInvoice.IDCardNumber,
                    "phone": this.ShopAddressInvoice.Phone,
                    "address": this.ShopAddressInvoice.TextAddress,
                    "postcodeMappingId": this.ShopAddressInvoice.Address.Id,
                    "shopLogo": this.ShopAddressInvoice.Photo != "" ? this.ShopAddressInvoice.Photo : null ,
                    "isDefault": this.ShopAddressInvoice.IsDefault,
                    "isDeleted": false,
                }, {headers: this.header_token} )
                this.$swal({
                  toast: true,
                  timer: 1500,
                  showConfirmButton: false,
                  position: 'top',
                  type: 'success',
                  title: 'ดำเนินการเรียบร้อย'
                })
                  this.dialog_ShopAddressInvoice = false
                  this.loading = false
                this.getDataShopAddressInvoice()
              } catch (e) {
                this.$swal({
                  type: 'warning',
                  title: 'มีข้อผิดพลาดบางอย่าง กรุณาลองใหม่อีกครั้ง!!',
                })
                this.loading = false
              }
            }

          },

          deleteShopAddressInvoice (AddressInvoceID) {
            this.$swal({
              position: "top",
              type: 'warning',
              title: 'ลบ ใช่หรือไม่ !!',
              confirmButtonText: 'ยืนยัน',
              cancelButtonText: 'ยกเลิก',
              showCancelButton: true,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              allowOutsideClick: false
            }).then(async(result) => {
              if (result.value) {
                this.deleteShopAddressInvoiceConfirm(AddressInvoceID)
              }
            })
          },

          async deleteShopAddressInvoiceConfirm (AddressInvoceID) {

            try {
              this.loading = true
              let response = await axios.post(shopService_dotnet+'ShopAddressInvoice/delete-shop-address-invoice', {
                'id': AddressInvoceID
              }, {headers: this.header_token} )
              this.$swal({
                toast: true,
                timer: 1500,
                showConfirmButton: false,
                position: 'top',
                type: 'success',
                title: 'ลบเรียบร้อย'
              })
              this.loading = false
              this.getDataShopAddressInvoice()
            } catch (e) {
              this.$swal({
                type: 'warning',
                title: 'มีข้อผิดพลาดบางอย่าง กรุณาลองใหม่อีกครั้ง!!',
              })
              this.loading = false
            }
          },


          async getShopAddressInvoiceForEdit (item) {
            this.ShopAddressInvoice.ID = item.id
            this.ShopAddressInvoice.Name = item.name
            this.ShopAddressInvoice.Phone = item.phone
            this.ShopAddressInvoice.IDCardNumber = item.idCardNumber
            this.ShopAddressInvoice.TextAddress = item.address
            this.ShopAddressInvoice.IsDefault = item.isDeleted
            this.Photo.Photo_link = item.shopLogo
            this.Data_ChannelSource.Ischannelsource = false
            this.ShopAddressInvoice.Address = {
               "Id": item.postcodeMappingID,
               "SubDistrict": item['subDistrict'],
               "District": item['district'],
               "Province": item['province'],
               "Postcode": item['postcode'],
               "ProvinceId": item['provinceID']
            }

            if (this.ShopAddressInvoice.IsDefault == true) {
                this.IsReadonly = true
            }

            this.dialog_ShopAddressInvoice = true

          },

          async BankAcountAction (item, type) {
            if (type == 'ADD') {
              this.clearDataBankAcount()
              this.dialog_ShopBankAcount = true
              this.ShopBankAcountType = type
              this.BankID = null
            } else if (type == 'EDIT') {
              await this.getDataBankAcountID(item)
              this.ShopBankAcountType = type
              this.BankID = item.id

            } else if (type == 'DELETE') {
              this.ShopBankAcountType = type
              this.$swal({
                position: "top",
                type: 'warning',
                title: 'ลบ ใช่หรือไม่ !!',
                confirmButtonText: 'ยืนยัน',
                cancelButtonText: 'ยกเลิก',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                allowOutsideClick: false
              }).then(async(result) => {
                if (result.value) {
                  this.ShopBankAcountType = type
                  this.BankID = item.id
                  this.BankAcountActionConfirm()
                }
              })
            } else if (type == 'DEFAULT') {
              this.$swal({
                position: "top",
                type: 'warning',
                title: 'ตั้งเป็นค่าเริ่มต้น ใช่หรือไม่ !!',
                confirmButtonText: 'ยืนยัน',
                cancelButtonText: 'ยกเลิก',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                allowOutsideClick: false
              }).then(async(result) => {
                if (result.value) {
                  this.ShopBankAcountType = type
                  this.BankID = item.id
                  this.BankAcountActionConfirm()
                }
              })
            }
          },

          async getDataBankAcount () {


            try {
              this.loading = true
              let response = await axios.post(shopService_dotnet+'ShopSetting/get-shop-bank-acount-setting', {
                'shopID': localStorage.getItem("shop_id")
              }, {headers: this.header_token} )

              if( response.data.length > 0){
                this.ShopBankAcountData = response.data
              }

              this.loading = false
            } catch (e) {
              this.$swal({
                type: 'warning',
                title: 'มีข้อผิดพลาดบางอย่าง กรุณาลองใหม่อีกครั้ง!!',
              })
              this.loading = false
            }
          },

          async getDataBankAcountID (item) {

            this.BanksID = item.bankId
            this.BanksName = item.bankName
            this.AccountNumber = item.accountNumber
            this.AccountName =  item.accountName
            this.AccountIsActive = item.isActive
            this.AccountIsDefault = item.isDefault

            if(this.AccountIsDefault == true) {
              this.IsReadonly = true
            }

            this.dialog_ShopBankAcount = true
            this.loading = false

          },

          async BankAcountActionConfirm () {
            if(this.ShopBankAcountType == 'ADD'){
              if(this.BanksID == null ){
                this.$refs.BanksID.focus()
                return
              }else if (this.AccountNumber == null || this.AccountNumber == '') {
                this.$refs.AccountNumber.focus()
                return
              }else if (this.AccountName == null || this.AccountName == '') {
                this.$refs.AccountName.focus()
                return
              }

              var BankListNameThai
              let BankListfiltered = this.BankList.find(x => x.id === this.BanksID)
              BankListNameThai = BankListfiltered['nameThai']


              try {
                this.loading = true
                let response = await axios.post(shopService_dotnet+'ShopSetting/add-shop-bank-acount-setting', {
                      "Id" : 0,
                      "ShopId" : localStorage.getItem("shop_id"),
                      "BankId" : this.BanksID,
                      "BankName" : BankListNameThai,
                      "AccountNumber" : this.AccountNumber,
                      "AccountName" : this.AccountName,
                      "IsActive" : true,
                      "IsDefault" : this.AccountIsDefault,
                      "IsDeleted" : false
                }, {headers: this.header_token} )

                if(response.data == 'สำเร็จ'){
                  this.$swal({
                    toast: true,
                    timer: 1500,
                    showConfirmButton: false,
                    position: 'top',
                    type: 'success',
                    title: 'ดำเนินการเรียบร้อย'
                  })
                  this.loading = false
                  this.dialog_ShopBankAcount = false
                  this.getDataBankAcount()
                } else {
                  this.$swal({
                    type: 'warning',
                    title: 'มีข้อผิดพลาดบางอย่าง กรุณาลองใหม่อีกครั้ง!!',
                  })
                  this.loading = false
                }

              } catch (e) {
                this.$swal({
                  type: 'warning',
                  title: 'มีข้อผิดพลาดบางอย่าง กรุณาลองใหม่อีกครั้ง!!',
                })
                this.loading = false
              }

            } else if (this.ShopBankAcountType == 'EDIT') {
              if( this.BanksID == null ){
                this.$refs.BanksID.focus()
                return
              }else if (this.AccountNumber == null || this.AccountNumber == '') {
                this.$refs.AccountNumber.focus()
                return
              }else if (this.AccountName == null || this.AccountName == '') {
                this.$refs.AccountName.focus()
                return
              }

              var BankListNameThaiEdit
              let BankListfiltered = this.BankList.find(x => x.id === this.BanksID)
              BankListNameThaiEdit = BankListfiltered['nameThai']

              try {
                this.loading = true
                let response = await axios.post(shopService_dotnet+'ShopSetting/add-shop-bank-acount-setting', {
                  "Id" : this.BankID,
                  "ShopId" : localStorage.getItem("shop_id"),
                  "BankId" : this.BanksID,
                  "BankName" : BankListNameThaiEdit,
                  "AccountNumber" : this.AccountNumber,
                  "AccountName" : this.AccountName,
                  "IsActive" : true,
                  "IsDefault" : this.AccountIsDefault,
                  "IsDeleted" : false
                }, {headers: this.header_token} )

                if(response.data == 'สำเร็จ'){
                  this.$swal({
                    toast: true,
                    timer: 1500,
                    showConfirmButton: false,
                    position: 'top',
                    type: 'success',
                    title: 'ดำเนินการเรียบร้อย'
                  })
                  this.loading = false
                  this.dialog_ShopBankAcount = false
                  this.getDataBankAcount()
                } else {
                  this.$swal({
                    type: 'warning',
                    title: 'มีข้อผิดพลาดบางอย่าง กรุณาลองใหม่อีกครั้ง!!',
                  })
                  this.loading = false
                }


              } catch (e) {
                this.$swal({
                  type: 'warning',
                  title: 'มีข้อผิดพลาดบางอย่าง กรุณาลองใหม่อีกครั้ง!!',
                })
                this.loading = false
              }


            } else if (this.ShopBankAcountType == 'DELETE' ) {
              this.loading = true
              let response = await axios.post(shopService_dotnet+'ShopSetting/delete-shop-bank-acount-setting', {
                "id" : this.BankID,
              }, {headers: this.header_token} )

              if(response.data == 'สำเร็จ'){
                this.$swal({
                  toast: true,
                  timer: 1500,
                  showConfirmButton: false,
                  position: 'top',
                  type: 'success',
                  title: 'ดำเนินการเรียบร้อย'
                })
                this.loading = false
                this.dialog_ShopBankAcount = false
                this.getDataBankAcount()
              } else {
                this.$swal({
                  type: 'warning',
                  title: 'มีข้อผิดพลาดบางอย่าง กรุณาลองใหม่อีกครั้ง!!',
                })
                this.loading = false
              }
            }
          },

          async UpdateAddOrderModeDisibled () {

            if (this.AddOrderMode == this.AddOrderMode_tmp) {
              this.addOrderModeDisibled = true
              return
            } else {

              try {
                this.loading = true
                let response = await axios.post(shopService_dotnet+'ShopSetting/update-shop-setting', {
                  'id': localStorage.getItem("shop_id"),
                  'addOrderMode': this.AddOrderMode,
                }, {headers: {'Content-Type': 'application/json', 'Authorization': 'Bearer ' + localStorage.getItem('token')}} )

                if(response.data == 'sucsess'){
                  this.shopDisibled = true
                  this.loading = false
                  this.addOrderModeDisibled = true
                  await this.data_shop()
                } else {
                  this.$swal({
                    type: 'warning',
                    title: 'มีข้อผิดพลาดบางอย่าง กรุณาลองใหม่อีกครั้ง!!',
                  })
                  this.loading = false
                }


              } catch (e) {
                  this.$swal({
                    type: 'warning',
                    title: 'มีข้อผิดพลาดบางอย่าง กรุณาลองใหม่อีกครั้ง!!',
                  })
              }
            }
          },

          async UpdateVatType(){
            try {
              this.loading = true
              let response = await axios.post(shopService_dotnet+'ShopSetting/update-shop-setting', {
                'id': localStorage.getItem("shop_id"),
                'defaultVatType': this.VatType,
              }, {headers: {'Content-Type': 'application/json', 'Authorization': 'Bearer ' + localStorage.getItem('token')}} )

              if(response.data == 'sucsess'){
                this.shopDisibled = true
                this.loading = false
                this.VatTypeDisibled = true
                await this.data_shop()
              } else {
                this.$swal({
                  type: 'warning',
                  title: 'มีข้อผิดพลาดบางอย่าง กรุณาลองใหม่อีกครั้ง!!',
                })
                this.loading = false
              }

            } catch (e) {
                this.$swal({
                  type: 'warning',
                  title: 'มีข้อผิดพลาดบางอย่าง กรุณาลองใหม่อีกครั้ง!!',
                })
            }
          },

          //img_chanalsource_avartar
          async ChannelSourceAction(item,Action){
            if(Action == "ADD"){
              this.chanal_actions = Action
              this.data_list_chanal = []
              this.input_chanalFileUpload = null
              this.input_chanalName = null
              this.PhotoLink_pre = null
              this.dialog_ChannelSource=true
            }else if(Action == "UPDATE"){
              this.chanal_actions = Action

              this.input_chanalName = item.channelName
              this.PhotoLink_pre = item.logoPic
              this.dialog_ChannelSource=true

              this.data_list_chanal = []
              var chanal_list = {
               "id": item.id,
               "photoLink": null,
               "photoLinkBeforeEditing": item.logoPic,
               "chanalName": null ,
               "shopID" : localStorage.getItem("shop_id")
             }
             this.data_list_chanal.push(chanal_list)



            }else if(Action == "DELETE"){
              this.$swal({
                position: "center",
                type: 'warning',
                title: 'ลบ ใช่หรือไม่ !!',
                confirmButtonText: 'ยืนยัน',
                cancelButtonText: 'ยกเลิก',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                allowOutsideClick: false
              }).then(async(result) => {
                if (result.value) {
                  this.chanal_actions = Action
                  this.data_list_chanal = []
                  var chanal_list = {
                   "id": item.id,
                   "photoLink": null,
                   "photoLinkBeforeEditing": null,
                   "chanalName": null ,
                   "shopID" : localStorage.getItem("shop_id")
                 }

                  this.data_list_chanal.push(chanal_list)
                  this.upload_order_update_data_chanal('DELETE')
                }
              })
            }
          },

          // AvatarCropper
          async handleUploading(from_data, xhr) {
            from_data.append("name", 'shop'+localStorage.getItem('branch_id')+this.DateNowNotNull()+'0')
            from_data.append('path', 'Shop/')
            this.Photo.PhotoLinkUpdate['from_Picture'] = from_data
            this.Photo.PhotoLinkUpdate['from_path'] = 'https://packhaistorage.s3-ap-southeast-1.amazonaws.com/Shop/'+'shop'+localStorage.getItem('branch_id')+this.DateNowNotNull()+'0'
            this.message = "uploading...";
          },
          async handleUploaded(response, from_data, xhr) {
            if (response=="OK") {
              await this.DelPicture()
              this.Photo.Photo_link = this.Photo.PhotoLinkUpdate['from_path']
              if(this.Data_ChannelSource.Ischannelsource == true){
                this.Data_ChannelSource.Photo = this.Photo.PhotoLinkUpdate['from_path']
              }else{
                this.ShopAddressInvoice.Photo = this.Photo.PhotoLinkUpdate['from_path']
              }
              this.message = "user avatar updated.";
            }
          },
          handleCompleted(response, from_data, xhr) {
            this.message = "upload completed.";
          },
          handlerError(message, type, xhr) {
            this.message = "Oops! Something went wrong...";
          },
          async DelPicture(){
            if (this.Photo.Photo_link!='' && this.Photo.Photo_link!=null){
              var pathRemove = this.Photo.Photo_link
              pathRemove = pathRemove.split("/");
              pathRemove = pathRemove[3]+'/'+pathRemove[4]
              let response = await axios.post(globalService+'removePicture', {'path':pathRemove})
              if (response.data =='OK'){
                this.Photo.Photo_link  = ''
                if(this.Data_ChannelSource.Photo!=''&& this.Data_ChannelSource.Photo!=null){
                  this.Data_ChannelSource.Photo  = ''
                }else if(this.ShopAddressInvoice.Photo!=''&& this.ShopAddressInvoice.Photo!=null){
                  this.ShopAddressInvoice.Photo = ''
                }
              }
            }
          },
          DateNowNotNull() {
            var today = new Date();
            var date = today.getFullYear()+''+((today.getMonth()+1).toString().length> 1 ? (today.getMonth()+1).toString() : '0'+(today.getMonth()+1).toString())+''+(today.getDate().toString().length> 1 ? today.getDate().toString() : '0'+today.getDate().toString());
            var h = today.getHours().toString().length> 1 ? today.getHours().toString() : '0'+today.getHours().toString()
            var m = today.getMinutes().toString().length> 1 ? today.getMinutes().toString() : '0'+today.getMinutes().toString()
            var s = today.getSeconds().toString().length> 1 ? today.getSeconds().toString() : '0'+today.getSeconds().toString()
            var time = h + '' + m + '' + s
            var newDatetime = date+time;
            return newDatetime
          },

          clearDataBankAcount () {
            this.BanksID= null
            this.BanksName = null
            this.AccountNumber= null
            this.AccountName= null
            this.AccountIsActive = true
            this.AccountIsDefault = false
          },

          clearDataAddressManage (){
            this.ShopAddressManage= {
              "ID":null,
              "Name": null,
              "Phone": null,
              "TextAddress": null,
              "Address": null,
              "PostcodeMappingID":null,
              "IsDefault": false,
            }
          },

          clearDataAddressInvoice (){
            this.ShopAddressInvoice= {
              "ID":null,
              "Name": null,
              "Phone": null,
              "IDCardNumber": null,
              "TextAddress": null,
              "Address": null,
              "PostcodeMappingID":null,
              "IsDefault": false,
              "Photo":''
            },
            this.Photo.Photo_link=''
          },
          async get_postcode_mapping () {
            let response = await axios.post(generalService_dotnet+'General/get-postcodemapping', {},{ headers: this.header_token})
            this.postcode_mapping = response.data
          },
          OpenPhoto(Photo){
            this.ShowPhotoLink = Photo
            this.ShowPhoto = true
          },
          Opentap(PhotoLink){
            window.open(PhotoLink);
          },

          async add_data_list_chanal () {
             if (this.input_chanalFileUpload == null) {
                this.$refs.input_chanalFileUpload.focus()
                return
            }else if (this.input_chanalName == null) {
                this.$refs.input_chanalName.focus()
                return
            }


             var fileToBase64 = null
             const toBase64 = file => new Promise((resolve, reject) => {
                 const reader = new FileReader()
                 reader.readAsDataURL(file)
                 reader.onload = () => resolve(reader.result)
                 reader.onerror = error => reject(error)
             })

             if (this.input_chanalFileUpload != null) {
               fileToBase64 = await toBase64(this.input_chanalFileUpload)
             } else {
               fileToBase64 = null
             }

             var data_list_chanal = []
             var chanal_list = {
              "id": this.data_list_chanal.length+1,
              "photoLink": fileToBase64,
              "photoLinkBeforeEditing": null,
              "chanalName": this.input_chanalName ,
              "shopID" : localStorage.getItem("shop_id")
            }

             this.data_list_chanal.push(chanal_list)
            //
            //  // เคลียข้อมูล
             this.input_chanalFileUpload = null
             this.input_chanalName = null
          },

          delete_data_list_chanal (id) {
            for (var e =0; e < this.data_list_chanal.length; e++){
              if(this.data_list_chanal[e]['id'] == id){
                this.data_list_chanal.splice(e, 1)
                break;
              }
            }
          },

          async upload_order_update_data_chanal (actionss) {

              if(actionss == "UPDATE"){
                    if (this.input_chanalFileUpload != null) {
                      var fileToBase64 = null
                      const toBase64 = file => new Promise((resolve, reject) => {
                          const reader = new FileReader()
                          reader.readAsDataURL(file)
                          reader.onload = () => resolve(reader.result)
                          reader.onerror = error => reject(error)
                      })

                      fileToBase64 = await toBase64(this.input_chanalFileUpload)
                      this.data_list_chanal[0].photoLink = fileToBase64
                    }

                    this.data_list_chanal[0].chanalName = this.input_chanalName

                    this.loading = true

                    let response = await axios.post(shopService_dotnet+'ShopSetting/add-or-update-shop-channelSource-setting', {
                      "data_chanal_list": this.data_list_chanal,
                      "action": actionss
                    },
                    { headers: this.header_token})
                    if(response.data == 'success'){
                      this.load_data()
                      this.dialog_ChannelSource = false
                    } else {
                      this.$swal({
                        type: 'warning',
                        title: 'มีข้อผิดพลาดบางอย่าง กรุณาลองใหม่อีกครั้ง!!',
                      })
                    }

                    this.loading = false


              } else if (actionss == "ADD") {
                    if(this.data_list_chanal.length == 0){
                        return
                    }


                    this.loading = true

                    let response = await axios.post(shopService_dotnet+'ShopSetting/add-or-update-shop-channelSource-setting', {
                      "data_chanal_list": this.data_list_chanal,
                      "action": actionss
                    },
                    { headers: this.header_token})
                    if(response.data == 'success'){
                      this.load_data()
                      this.dialog_ChannelSource = false
                    } else {
                      this.$swal({
                        type: 'warning',
                        title: 'มีข้อผิดพลาดบางอย่าง กรุณาลองใหม่อีกครั้ง!!',
                      })
                    }

                    this.loading = false

              } else if (actionss == "DELETE") {
                let response = await axios.post(shopService_dotnet+'ShopSetting/shop-delete-chanal', {
                  "chanalID": this.data_list_chanal[0].id,
                  "shopID": localStorage.getItem("shop_id")
                },
                { headers: this.header_token})
                if(response.data == 'success'){
                  this.load_data()
                  this.dialog_ChannelSource = false
                } else {
                  this.$swal({
                    type: 'warning',
                    title: 'มีข้อผิดพลาดบางอย่าง กรุณาลองใหม่อีกครั้ง!!',
                  })
                }

              }
          },

          async set_default_express (item){

            try {
              let response = await axios.post(shopService_dotnet+'ShopSetting/set-default-shop-express-setting', {
                "expressID": item.id,
                "shopID": localStorage.getItem("shop_id")
              },
              { headers: this.header_token})

              this.dialog_ExpressManage = false
              this.express_data = null
              this.load_data()
            } catch (e) {
              this.$swal({
                type: 'warning',
                title: 'set-default-shop-express-setting มีข้อผิดพลาด !!',
                text: e.message
              })
            }
          },

        }
    }
</script>
<style scoped>
  >>>.vue-avatar-cropper-demo {
    max-width: 18em;
    margin: 0 auto;
  }
  >>>.avatar {
    width: 160px;
    border-radius: 6px;
    display: block;
    margin: 20px auto;
  }
</style>
