export const manage_customer_language = {
    TH: {
        customer:"ลูกค้า",
        search:"ค้นหา",
        name:"ชื่อ",
        tel:"เบอร์โทร",
        email:"อีเมลล์",
        line:"Line",
        facebook:"Facebook",
        address:"ที่อยู่",
        details:"รายละเอียด",
        see_details:"ดูรายละเอียด",
        add:"เพิ่ม",
        number_customers:"จำนวนลูกค้า",
        people:"คน",
        customer_info:"ข้อมูลลูกค้า",
        note:"หมายเหตุ",
        delivery_address:"ที่อยู่จัดส่ง",
        record:"บันทึก",
        default:"ค่าเริ่มต้น",
        tax_invoice_address:"ที่อยู่ใบกำกับภาษี",
        edit_tax_invoice_address:"แก้ไขที่อยู่ใบกำกับภาษี",
        add_tax_invoice_address:"เพิ่มที่อยู่ที่อยู่ใบกำกับภาษี",
        tax_invoice_number:"เลขผู้เสียภาษี",
        add_address:"เพิ่มที่อยู่จัดส่ง",
        edit_address:"แก้ไขที่อยู่จัดส่ง",
        zipcode:"ไปรษณีย์",
        set_default:"ตั้งเป็นค่าเริ่มต้น",
        all_address:"รหัสไปรษณีย์ / จังหวัด / (เขต/อำเภอ) / (แขวง/ตำบล)",
        add_new_customers:"เพิ่มลูกค้าใหม่",
        customers_info:"ข้อมูลลูกค้า",
        number:"ลำดับ",
        edit:"แก้ไข",
        delete:"ลบ",

  
  },
    EN: {
   
        customer:"ลูกค้า",
        search:"ค้นหา",
        name:"ชื่อ",
        tel:"เบอร์โทร",
        email:"อีเมลล์",
        line:"Line",
        facebook:"Facebook",
        address:"ที่อยู่",
        details:"รายละเอียด",
        see_details:"ดูรายละเอียด",
        add:"เพิ่ม",
        number_customers:"จำนวนลูกค้า",
        people:"คน",
        customer_info:"ข้อมูลลูกค้า",
        note:"หมายเหตุ",
        delivery_address:"ที่อยู่จัดส่ง",
        record:"บันทึก",
        default:"ค่าเริ่มต้น",
        tax_invoice_address:"ที่อยู่ใบกำกับภาษี",
        edit_tax_invoice_address:"แก้ไขที่อยู่ใบกำกับภาษี",
        add_tax_invoice_address:"เพิ่มที่อยู่ที่อยู่ใบกำกับภาษี",
        tax_invoice_number:"เลขผู้เสียภาษี",
        add_address:"เพิ่มที่อยู่จัดส่ง",
        edit_address:"แก้ไขที่อยู่จัดส่ง",
        zipcode:"ไปรษณีย์",
        set_default:"ตั้งเป็นค่าเริ่มต้น",
        all_address:"รหัสไปรษณีย์ / จังหวัด / (เขต/อำเภอ) / (แขวง/ตำบล)",
        add_new_customers:"เพิ่มลูกค้าใหม่",
        customers_info:"ข้อมูลลูกค้า",
        number:"ลำดับ",
        edit:"แก้ไข",
        delete:"ลบ",

     },
    CH: {
        customer:"ลูกค้า",
        search:"ค้นหา",
        name:"ชื่อ",
        tel:"เบอร์โทร",
        email:"อีเมลล์",
        line:"Line",
        facebook:"Facebook",
        address:"ที่อยู่",
        details:"รายละเอียด",
        see_details:"ดูรายละเอียด",
        add:"เพิ่ม",
        number_customers:"จำนวนลูกค้า",
        people:"คน",
        customer_info:"ข้อมูลลูกค้า",
        note:"หมายเหตุ",
        delivery_address:"ที่อยู่จัดส่ง",
        record:"บันทึก",
        default:"ค่าเริ่มต้น",
        tax_invoice_address:"ที่อยู่ใบกำกับภาษี",
        edit_tax_invoice_address:"แก้ไขที่อยู่ใบกำกับภาษี",
        add_tax_invoice_address:"เพิ่มที่อยู่ที่อยู่ใบกำกับภาษี",
        tax_invoice_number:"เลขผู้เสียภาษี",
        add_address:"เพิ่มที่อยู่จัดส่ง",
        edit_address:"แก้ไขที่อยู่จัดส่ง",
        zipcode:"ไปรษณีย์",
        set_default:"ตั้งเป็นค่าเริ่มต้น",
        all_address:"รหัสไปรษณีย์ / จังหวัด / (เขต/อำเภอ) / (แขวง/ตำบล)",
        add_new_customers:"เพิ่มลูกค้าใหม่",
        customers_info:"ข้อมูลลูกค้า",
        number:"์num",
        edit:"แก้ไข",
        delete:"ลบ",

    }
}
