export const setting_language = {
    TH: {
    //   
        newtab:"เปิดแถบใหม่",
        close:"ปิด",
        setting:"ตั้งค่า",
        shop:"ร้านค้า",
        shop_info:"ข้อมูลร้านค้า",
        edit:"แก้ไข",
        shop_name:"ชื่อร้านค้า",
        create_order:"สร้างออเดอร์",
        email:"อีเมล",
        record:"บันทึก",
        tax_invoice_address:"ที่อยู่ใบกำกับภาษี",
        default:"ค่าเริ่มต้น",
        delivery_address:"ที่อยู่จัดส่ง",
        bank_account:"บัญชีธนาคาร",
        bank_name:"ชื่อธนาคาร",
        bank_number:"เลขบัญชี",
        add_bankt:"เพิ่มบัญชีธนาคาร",
        edit_bank:"แก้ไขบัญชีธนาคาร",
        enable:"เปิดใช้งาน",
        disable:"ไม่เปิดใช้งาน",
        open_order_by_customer_transfer:"การเปิดออเดอร์ในกรณีลูกค้าโอนเงิน",
        set_tax_type_by_open_order:"ตั้งค่าประเภทภาษีตอนเปิดออเดอร์",
        tax_type:"ประเภทใบแจ้งหนี้",
        unspecified:"ไม่ระบุประเภท",
        channel:"ช่องทาง",
        sales_channel:"ช่องทางการขาย",
        edit_sales_channel:"แก้ไขช่องทางการขาย",
        add_sales_channel:"เพิ่มช่องทางการขาย",
        add_channel:"เพิ่มช่องทาง",
        edit_address:	"แก้ไขที่อยู่จัดส่ง",
        add_address:	"เพิ่มที่อยู่จัดส่ง",
        name_person_juristic:" ชื่อบุคคล/นิติบุคคล",
        tel:	"เบอร์โทร",
        address:	"ที่อยู่",
        zipcode:	"ไปรษณีย์",
        set_default:	"ตั้งเป็นค่าเริ่มต้น",
        edit_tax_invoice_address:	"แก้ไขที่อยู่ใบกำกับภาษี",
        add_tax_invoice_address:	"เพิ่มที่อยู่ที่อยู่ใบกำกับภาษี",
        no_picture:"No Picture",
        picture:"รูป",
        tax_invoice_number:	"เลขประจำตัวผู้เสียภาษี",
        status:"สถานะ",

        //ส่วนของ v-radio
        open_order_wati_pack:'เปิดออเดอร์แล้ว ให้สถานะไปที่ "รอแพ็ค"',
        open_order_wati_balance:'เปิดออเดอร์แล้ว ให้สถานะไปที่ "รอแพ็ค"',
        no_vat:"ไม่มีภาษี (NO Vat)",
        vat_included:"รวมภาษี (VAT Included",
        vat_excluded:"ไม่รวมภาษี (VAT Excluded",
        create_tax_Manual:"สร้างใบแจ้งหนี้และหักเงินจากบัญชีโดยสาขาแบบ Manual",
        deduct_money_not_create_tax:"หักเงินจากบัญชีโดยอัตโนมัติทุกวัน ( ระบบไม่ทำการสร้างใบแจ้งหนี้รายวัน )",
        deduct_money_create_tax:"หักเงินจากบัญชีโดยอัตโนมัติทุกวัน ( ระบบทำการสร้างใบแจ้งหนี้รายวัน )",
        

        //ส่วนของ script
        submit:"ตกลง",
        cancel:"ยกเลิก",
        warning_error_try_again:"มีข้อผิดพลาดบางอย่าง กรุณาลองใหม่อีกครั้ง!!",
        warning_yes_no:"ลบ ใช่หรือไม่ !!",
        confirm:"ยืนยัน",
        done:"ดำเนินการเรียบร้อย",
        delete_done:"ลบเรียบร้อย",
        warning_set_default:"ตั้งเป็นค่าเริ่มต้น ใช่หรือไม่ !!",
        warning_picture:"กรุณาใส่รูปภาพ",
        warning_channel:"กรุณาใส่ช่อง",


   
    },
    EN: {

        newtab:"เปิดแถบใหม่",
        close:"ปิด",
        setting:"ตั้งค่า",
        shop:"ร้านค้า",
        shop_info:"ข้อมูลร้านค้า",
        edit:"แก้ไข",
        shop_name:"ชื่อร้านค้า",
        create_order:"สร้างออเดอร์",
        email:"อีเมล",
        record:"บันทึก",
        tax_invoice_address:"ที่อยู่ใบกำกับภาษี",
        default:"ค่าเริ่มต้น",
        delivery_address:"ที่อยู่จัดส่ง",
        bank_account:"บัญชีธนาคาร",
        bank_name:"ชื่อธนาคาร",
        bank_number:"เลขบัญชี",
        add_bankt:"เพิ่มบัญชีธนาคาร",
        edit_bank:"แก้ไขบัญชีธนาคาร",
        enable:"เปิดใช้งาน",
        disable:"ไม่เปิดใช้งาน",
        open_order_by_customer_transfer:"การเปิดออเดอร์ในกรณีลูกค้าโอนเงิน",
        set_tax_type_by_open_order:"ตั้งค่าประเภทภาษีตอนเปิดออเดอร์",
        tax_type:"ประเภทใบแจ้งหนี้",
        unspecified:"ไม่ระบุประเภท",
        channel:"ช่องทาง",
        sales_channel:"ช่องทางการขาย",
        edit_sales_channel:"แก้ไขช่องทางการขาย",
        add_sales_channel:"เพิ่มช่องทางการขาย",
        add_channel:"เพิ่มช่องทาง",
        edit_address:	"แก้ไขที่อยู่จัดส่ง",
        add_address:	"เพิ่มที่อยู่จัดส่ง",
        name_person_juristic:" ชื่อบุคคล/นิติบุคคล",
        tel:	"เบอร์โทร",
        address:	"ที่อยู่",
        zipcode:	"ไปรษณีย์",
        set_default:	"ตั้งเป็นค่าเริ่มต้น",
        edit_tax_invoice_address:	"แก้ไขที่อยู่ใบกำกับภาษี",
        add_tax_invoice_address:	"เพิ่มที่อยู่ที่อยู่ใบกำกับภาษี",
        no_picture:"No Picture",
        picture:"รูป",
        tax_invoice_number:	"เลขประจำตัวผู้เสียภาษี",
        status:"สถานะ",

        //ส่วนของ v-radio
        open_order_wati_pack:'เปิดออเดอร์แล้ว ให้สถานะไปที่ "รอแพ็ค"',
        open_order_wati_balance:'เปิดออเดอร์แล้ว ให้สถานะไปที่ "รอแพ็ค"',
        no_vat:"ไม่มีภาษี (NO Vat)",
        vat_included:"รวมภาษี (VAT Included",
        vat_excluded:"ไม่รวมภาษี (VAT Excluded",
        create_tax_Manual:"สร้างใบแจ้งหนี้และหักเงินจากบัญชีโดยสาขาแบบ Manual",
        deduct_money_not_create_tax:"หักเงินจากบัญชีโดยอัตโนมัติทุกวัน ( ระบบไม่ทำการสร้างใบแจ้งหนี้รายวัน )",
        deduct_money_create_tax:"หักเงินจากบัญชีโดยอัตโนมัติทุกวัน ( ระบบทำการสร้างใบแจ้งหนี้รายวัน )",
        

        //ส่วนของ script
        submit:"ตกลง",
        cancel:"ยกเลิก",
        warning_error_try_again:"มีข้อผิดพลาดบางอย่าง กรุณาลองใหม่อีกครั้ง!!",
        warning_yes_no:"ลบ ใช่หรือไม่ !!",
        confirm:"ยืนยัน",
        done:"ดำเนินการเรียบร้อย",
        delete_done:"ลบเรียบร้อย",
        warning_set_default:"ตั้งเป็นค่าเริ่มต้น ใช่หรือไม่ !!",
        warning_picture:"กรุณาใส่รูปภาพ",
        warning_channel:"กรุณาใส่ช่อง",

       

    },
    CH: {

        newtab:"เปิดแถบใหม่",
        close:"ปิด",
        setting:"ตั้งค่า",
        shop:"ร้านค้า",
        shop_info:"ข้อมูลร้านค้า",
        edit:"แก้ไข",
        shop_name:"ชื่อร้านค้า",
        create_order:"สร้างออเดอร์",
        email:"อีเมล",
        record:"บันทึก",
        tax_invoice_address:"ที่อยู่ใบกำกับภาษี",
        default:"ค่าเริ่มต้น",
        delivery_address:"ที่อยู่จัดส่ง",
        bank_account:"บัญชีธนาคาร",
        bank_name:"ชื่อธนาคาร",
        bank_number:"เลขบัญชี",
        add_bankt:"เพิ่มบัญชีธนาคาร",
        edit_bank:"แก้ไขบัญชีธนาคาร",
        enable:"เปิดใช้งาน",
        disable:"ไม่เปิดใช้งาน",
        open_order_by_customer_transfer:"การเปิดออเดอร์ในกรณีลูกค้าโอนเงิน",
        set_tax_type_by_open_order:"ตั้งค่าประเภทภาษีตอนเปิดออเดอร์",
        tax_type:"ประเภทใบแจ้งหนี้",
        unspecified:"ไม่ระบุประเภท",
        channel:"ช่องทาง",
        sales_channel:"ช่องทางการขาย",
        edit_sales_channel:"แก้ไขช่องทางการขาย",
        add_sales_channel:"เพิ่มช่องทางการขาย",
        add_channel:"เพิ่มช่องทาง",
        edit_address:	"แก้ไขที่อยู่จัดส่ง",
        add_address:	"เพิ่มที่อยู่จัดส่ง",
        name_person_juristic:" ชื่อบุคคล/นิติบุคคล",
        tel:	"เบอร์โทร",
        address:	"ที่อยู่",
        zipcode:	"ไปรษณีย์",
        set_default:	"ตั้งเป็นค่าเริ่มต้น",
        edit_tax_invoice_address:	"แก้ไขที่อยู่ใบกำกับภาษี",
        add_tax_invoice_address:	"เพิ่มที่อยู่ที่อยู่ใบกำกับภาษี",
        no_picture:"No Picture",
        picture:"รูป",
        tax_invoice_number:	"เลขประจำตัวผู้เสียภาษี",
        status:"สถานะ",

        //ส่วนของ v-radio
        open_order_wati_pack:'เปิดออเดอร์แล้ว ให้สถานะไปที่ "รอแพ็ค"',
        open_order_wati_balance:'เปิดออเดอร์แล้ว ให้สถานะไปที่ "รอแพ็ค"',
        no_vat:"ไม่มีภาษี (NO Vat)",
        vat_included:"รวมภาษี (VAT Included",
        vat_excluded:"ไม่รวมภาษี (VAT Excluded",
        create_tax_Manual:"สร้างใบแจ้งหนี้และหักเงินจากบัญชีโดยสาขาแบบ Manual",
        deduct_money_not_create_tax:"หักเงินจากบัญชีโดยอัตโนมัติทุกวัน ( ระบบไม่ทำการสร้างใบแจ้งหนี้รายวัน )",
        deduct_money_create_tax:"หักเงินจากบัญชีโดยอัตโนมัติทุกวัน ( ระบบทำการสร้างใบแจ้งหนี้รายวัน )",
        

        //ส่วนของ script
        submit:"ตกลง",
        cancel:"ยกเลิก",
        warning_error_try_again:"มีข้อผิดพลาดบางอย่าง กรุณาลองใหม่อีกครั้ง!!",
        warning_yes_no:"ลบ ใช่หรือไม่ !!",
        confirm:"ยืนยัน",
        done:"ดำเนินการเรียบร้อย",
        delete_done:"ลบเรียบร้อย",
        warning_set_default:"ตั้งเป็นค่าเริ่มต้น ใช่หรือไม่ !!",
        warning_picture:"กรุณาใส่รูปภาพ",
        warning_channel:"กรุณาใส่ช่อง",

      

    }
}
