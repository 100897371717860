export const feedback_language = {
    TH: {
        feedback: "ข้อเสนอแนะ",
        PACKHAI_feedback: "PACKHAI ข้อเสนอแนะ",
        PACKHAI_feedback_detail: "PACKHAI ข้อเสนอแนะช่วยให้คุณสามารถแสดงความคิดและข้อเสนอแนะต่างๆได้",
        PACKHAI_feedback_text: "ข้อเสนอแนะที่นี้ (อย่างน้อย 10 ตัวอักษร)",
        send_feedback: "ส่งข้อเสนอแนะ",

    },
    EN: {
        feedback:"ข้อเสนอแนะ",
        PACKHAI_feedback:"PACKHAI ข้อเสนอแนะ",
        PACKHAI_feedback_detail:"PACKHAI ข้อเสนอแนะช่วยให้คุณสามารถแสดงความคิดและข้อเสนอแนะต่างๆได้",
        PACKHAI_feedback_text:"ข้อเสนอแนะที่นี้ (อย่างน้อย 10 ตัวอักษร)",
        send_feedback:"ส่งข้อเสนอแนะ",

    },
    CH: {
        feedback:"ข้อเสนอแนะ",
        PACKHAI_feedback:"PACKHAI ข้อเสนอแนะ",
        PACKHAI_feedback_detail:"PACKHAI ข้อเสนอแนะช่วยให้คุณสามารถแสดงความคิดและข้อเสนอแนะต่างๆได้",
        PACKHAI_feedback_text:"ข้อเสนอแนะที่นี้ (อย่างน้อย 10 ตัวอักษร)",
        send_feedback:"ส่งข้อเสนอแนะ",

    }
}
